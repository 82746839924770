import React from 'react'

const About = () => {
  return (
    <div className='py-3 px-4 md:px-9'>
      <h2 className='text-center border-b-1 border-gray-300 pb-2 mb-8 font-semibold'>About Wreckonnect</h2>
      <div className='text-sm'>
        <p className=''>Wreckonnect is an online platform dedicated to simplifying the process of procuring vehicle parts. The website caters to two primary users - customers and suppliers. Our platform connects automotive enthusiasts, repair shops, and car owners with reliable suppliers who offer a wide range of vehicle components. The seamless interaction between these two entities streamlines the purchase and delivery of vehicle parts.</p>
        <div>
          <p className='font-semibold mt-9'>Key Features:</p>
          <div className=''>
            <ol className='list-decimal ml-4'>
              <li className='font-semibold mt-5'>Customer Request Generation:</li>
              <ol className='list-disc ml-7 mt-2'>
                <li>Customers can submit requests for specific vehicle parts they need.</li>
                <li>Detailed information, such as vehicle model, part description, and any additional specifications, can be included in the request.</li>
              </ol>
              <li className='font-semibold mt-5'>Supplier Quote Creation:</li>
              <ol className='list-disc ml-7 mt-2'>
                <li>Suppliers receive customer requests and can create customized quotes based on the provided information.</li>
                <li>Quotes may include details like pricing, availability, and estimated delivery time.</li>
              </ol>
              <li className='font-semibold mt-5'>Discussion and Approval:</li>
              <ol className='list-disc ml-7 mt-2'>
                <li>Customers and suppliers can engage in discussions to refine details or negotiate terms.</li>
                <li>Once a quote is agreed upon, the customer approves it to initiate the next steps by supplier.</li>
              </ol>
              <li className='font-semibold mt-5'>Shipping and Order Tracking:</li>
              <ol className='list-disc ml-7 mt-2'>
                <li>Upon approval, the supplier ships the requested parts to the customer's specified address.</li>
                <li>Suppliers update the order status, allowing customers to track the progress of their order in real-time.</li>
              </ol>
              <li className='font-semibold mt-5'>Offline Payment:</li>
              <ol className='list-disc ml-7 mt-2'>
                <li>The payment process is handled offline, providing flexibility for various payment methods such as bank transfers or checks or cash.</li>
                <li>Payment details and instructions are communicated directly between the customer and supplier.</li>
              </ol>
              <li className='font-semibold mt-5'>User-Friendly Interface:</li>
              <ol className='list-disc ml-7 mt-2'>
                <li>Intuitive design ensures easy navigation for both customers and suppliers.</li>
                <li>Clear communication channels facilitate smooth interactions throughout the buying process.</li>
              </ol>
            </ol>
            <p className='mt-7'>Wreckonnect aims to create a transparent and efficient ecosystem for the online procurement of vehicle parts, fostering collaboration between customers and suppliers in the automotive industry.</p>
          </div>
        </div>
      </div>
    </div>
  )
}

export default About;
