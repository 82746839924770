import { Divider } from "@nextui-org/react";
import React from "react";

const SettingsLayout = ({ children, heading, divider }) => {
  return (
    <div>
      <div className="grid md:grid-cols-[0.5fr_0.06fr_1fr]">
        <div className="mb-2 md:mb-0 font-semibold">
          <h4>{heading}</h4>
        </div>
        {divider !== false ? <Divider orientation="vertical" className="hidden md:block" /> : <div></div>}
        <div>{children}</div>
      </div>
    </div>
  );
};

export default SettingsLayout;
