import React from "react";
import { FaArrowRightFromBracket } from "react-icons/fa6";
import { AiOutlineInfoCircle, AiOutlineShopping } from "react-icons/ai";
import { RiFileCopyLine, RiUserLine, RiBarChart2Line } from "react-icons/ri";
import { NavLink } from "react-router-dom";
import { LuUsers2 } from "react-icons/lu";

const data = [
  {
    id: 1,
    name: "Requests",
    link: "requests",
    icon: <FaArrowRightFromBracket />,
  },
  {
    id: 2,
    name: "Quotes",
    link: "quotes",
    icon: <RiFileCopyLine />,
  },
  {
    id: 3,
    name: "Orders",
    link: "orders",
    icon: <AiOutlineShopping />,
  },
  {
    id:4,
    name: "Dispute/Request Credit",
    link: "support",
    icon: <AiOutlineInfoCircle />,
  },
  {
    id:5,
    name: "Administration",
    link: "administration",
    icon: <RiUserLine />,
  },
  {
    id:6,
    name: "Users",
    link: "users",
    icon: <LuUsers2 />,
  },
  {
    id:7,
    name: "Reports",
    link: "report",
    icon: <RiBarChart2Line />,
  },
];

const MobileNav = ({isOpen}) => {
  let role = localStorage.getItem("role");
  return (
    <div className="fixed bottom-0 z-[999] w-full px-4 py-4 bg-white shadow-2xl lg:hidden">
      <div className="flex justify-around transition-all ">
        {data.map((item) => {
          const { id, name, link, icon } = item;
          return (
            <NavLink
              to={link}
              key={id}
              className={`flex flex-col items-center gap-y-1 font-semibold opacity-50  ${
                  (role === "CUSTOMER" || role === "SUPPLIER") && id > 4
                    ? "hidden"
                    : "block"
                } ${
                  (role === "ADMIN") && id < 5
                    ? "hidden"
                    : "block"
                } gap-3`}
            >
              <span className="text-lg">{icon}</span>
              <span className="text-xs">{name}</span>
            </NavLink>
          );
        })}
      </div>
    </div>
  );
};

export default MobileNav;
