import React, { useCallback, useEffect, useMemo, useState } from "react";
import { Link } from "react-router-dom";
import {
  Table,
  TableHeader,
  TableColumn,
  TableBody,
  TableRow,
  TableCell,
  Input,
  Chip,
  Pagination,
  Tabs,
  Tab,
  Divider,
  Spinner,
} from "@nextui-org/react";
import { FiSearch } from "react-icons/fi";
import { columns, statusOptions } from "../data/quotesData";
import { useDispatch, useSelector } from "react-redux";
import { changeStatus, getAllQuotes } from "../store/slices/supplierSlice";
import axios from "axios";
import { displayDate, formatTime } from "../utils";
import { STATUS } from "../store/constants";

const statusColorMap = {
  // eslint-disable-next-line no-useless-computed-key
  ["awaiting approval"]: "primary",
  // eslint-disable-next-line no-useless-computed-key
  ["in progress"]: "warning",
  accepted: "success",
  dispute: "warning",
  rejected: "danger",
  discarded: "danger",
  cancelled: "warning",
  ["Order created"]: "success"
};

// visible columns in the table
const INITIAL_VISIBLE_COLUMNS = ["status", "customer", "company", "car", "part", "duration"];

const   Quotes = () => {
  const accessToken = localStorage.getItem("accessToken");
  const dispatch = useDispatch();
  const { status } = useSelector((state) => state.supplier);
  const [quotes, setQuotes] = useState([]);
  const [filterValue, setFilterValue] = useState("");
  let role = localStorage.getItem("role");
  const [visibleColumns] = useState(new Set(INITIAL_VISIBLE_COLUMNS));
  const [statusFilter, setStatusFilter] = useState("all");
  const [rowsPerPage, setRowsPerPage] = useState(6);
  const [sortDescriptor, setSortDescriptor] = useState({
    column: "age",
    direction: "ascending",
  });
  const [page, setPage] = useState(1);
  const [totalPages, setTotalPages] = useState(0);
  const [totalRecords, setTotalRecords] = useState('');

  // getting all quotes
  const fetchData = async (page, pageSize) => {
    try {
      dispatch(changeStatus(STATUS.LOADING));
      setQuotes([]);
      const res = await axios.get(
        `${process.env.REACT_APP_SERVER_URL}/quotes/?page=${page}&pageSize=${pageSize}`,
        // `http://192.168.1.39:8082/api/v1/quotes/?page=${page}&pageSize=${pageSize}`,
        {
          headers: {
            authorization: `Bearer ${accessToken}`,
          },
        }
      );
      let data = res.data.details?.rows;
      let index = res.data?.details?.index;
      data.sort((a, b) => {
        let date1 = new Date(a?.createdAt);
        let date2 = new Date(b?.createdAt);
        return date2 - date1;
      });
      // dispatch(getAllQuotes(data));
      setQuotes(data);
      setTotalPages(index?.totalPages);
      setTotalRecords(index?.totalRecords);
      dispatch(changeStatus(STATUS.IDLE));
    } catch (error) {
      dispatch(changeStatus(STATUS.ERROR));
      console.log(error);
    }
  }

  useEffect(() => {
    fetchData(page, rowsPerPage);
  }, [dispatch, accessToken,totalPages, page, rowsPerPage]);

  useEffect(()=>{},[ quotes,totalPages, status ])

  const hasSearchFilter = Boolean(filterValue);

  const headerColumns = useMemo(() => {
    if (visibleColumns === "all") {
      return columns
    };
    let column = [...columns];

    if(role==="SUPPLIER"){
      column = column.filter((item)=>{
        return item.uid!=="company"
      })
    }else if(role==="CUSTOMER"){
      column = column.filter((item)=>{
        return item.uid!=="customer"
      })
    }
 
    return column.filter((colum) =>
      Array.from(visibleColumns).includes(colum.uid)
    );
  }, [visibleColumns]);

  // search & status filter
  const filteredItems = useMemo(() => {
    let filteredData = [...quotes];

    // search-filter
    if (hasSearchFilter) {
      filteredData = filteredData.filter(
        (data) =>
          data?.Request?.VehicleMake?.name
            .toLowerCase()
            .includes(filterValue.toLowerCase()) ||
          data?.Request?.VehicleModel?.name
            .toLowerCase()
            .includes(filterValue.toLowerCase()) ||
          data?.Request?.Part?.name
            .toLowerCase()
            .includes(filterValue.toLowerCase())
      );
    }

    // status-filter
    if (
      statusFilter !== "all" &&
      Array.from(statusFilter).length !== statusOptions.length
    ) {
      filteredData = filteredData.filter((data) =>
        statusFilter === data?.QuoteStatus?.status.toLowerCase()
      );
    }

    return filteredData;
  }, [quotes, hasSearchFilter, statusFilter, filterValue]);

  const pages = totalPages;

  const items = useMemo(() => {
    return filteredItems
  }, [page, filteredItems, rowsPerPage]);

  const sortedItems = useMemo(() => {
    return [...items].sort((a, b) => {
      const first = a[sortDescriptor.column];
      const second = b[sortDescriptor.column];
      const cmp = first < second ? -1 : first > second ? 1 : 0;

      return sortDescriptor.direction === "descending" ? -cmp : cmp;
    });
  }, [sortDescriptor, items]);

  const renderCell = useCallback((data, columnKey) => {
    const cellValue = data[columnKey];
  
    switch (columnKey) {
      case "status":
        return (
          <Chip
            className="capitalize"
            color={statusColorMap[data?.QuoteStatus?.status.toLowerCase()]}
            variant="flat"
          >
            {data?.QuoteStatus?.status}
          </Chip>
        );

        case "company" :
          return (
            role==="CUSTOMER" && <div>
            <p className="table_cell">{data.User?.company_name}</p>
          </div>
        );

        case "customer" :
          return (
            role==="SUPPLIER" && <div>
            <p className="table_cell">{data.Request?.User?.first_name + " " + data.Request?.User?.last_name}</p>
          </div>
        );

      case "car":
        return (
          <div className="flex flex-col gap-1">
            <p className="table_cell">
              {data?.Request?.VehicleMake ? data?.Request?.VehicleMake.name : data?.Request?.custom_make}{" "}
              {data?.Request?.VehicleModel ? data?.Request?.VehicleModel.name : data?.Request?.custom_model}
            </p>
          </div>
        );

      case "part":
        return (
          <div>
            <p className="table_cell">
              {data?.Request?.Part ? data?.Request?.Part?.name : data?.Request?.custom_part_type}
            </p>
          </div>
        );

      case "duration":
        return (
          <div className="flex flex-col gap-1">
            <p className="table_cell normal-case">
              {formatTime(data?.createdAt)}
            </p>
            <p className="table_cell_sm">{displayDate(data?.createdAt)}</p>
          </div>
        );

      default:
        return cellValue;
    }
  }, []);

  const onRowsPerPageChange = useCallback((e) => {
    setRowsPerPage(Number(e.target.value));
    setPage(1); 
    fetchData(1, e.target.value);
  }, []);

  const onSearchChange = useCallback((value) => {
    if (value) {
      setFilterValue(value);
      // setPage(1);
    } else {
      setFilterValue("");
    }
  }, []);

  const onClear = useCallback(() => {
    setFilterValue("");
    // setPage(1);
  }, []);

  // top content
  const topContent = useMemo(() => {
    return (
      <div className="flex flex-col gap-6">
        <div className="flex justify-between items-center gap-3">
          <Input
            isClearable
            className="w-full sm:max-w-[44%]"
            placeholder="Search quotes..."
            startContent={<FiSearch />}
            value={filterValue}
            onClear={() => onClear()}
            onValueChange={onSearchChange}
          />
        </div>

        <div className="flex flex-col-reverse sm:flex-row flex-wrap items-center justify-between gap-y-4">
          <div>
            <Tabs
              aria-label="Options"
              variant="underlined"
              selectedKey={statusFilter}
              onSelectionChange={setStatusFilter}
              className="mobile-only:[&>div]:grid mobile-only:[&>div]:grid-cols-2 mobile-only:[&>div]:gap-2"
            >
              <Tab key="all" title="All quotes"></Tab>
              <Tab key="awaiting approval" title="Awaiting approval"></Tab>
              <Tab key="in progress" title="In progress"></Tab>
              <Tab key="accepted" title="Quotes accepted"></Tab>
            </Tabs>
          </div> 

          <div className="flex items-center gap-5 text-xs sm:text-sm text-default-400">
            <span>Total: {totalRecords} quotes</span>
            <Divider orientation="vertical" className="h-6" />
            <label className="flex items-center">
              Rows per page:
              <select
                className="bg-transparent outline-none"
                onChange={onRowsPerPageChange}
              >
                <option value="6">6</option>
                <option value="10">10</option>
                <option value="15">15</option>
              </select>
            </label>
          </div>
        </div>
      </div>
    );
  }, [
    filterValue,
    onSearchChange,
    statusFilter,
    quotes.length,
    onRowsPerPageChange,
    onClear,
  ]);

  const bottomContent = useMemo(() => {
    return (
      <div className="py-2 px-2 flex justify-center items-center">
        <Pagination
          isCompact
          showControls
          showShadow
          color="primary"
          page={page}
          total={pages === 0 ? pages + 1 : pages}
          onChange={setPage}
        />
      </div>
    );
  }, [page, pages]);

  return (
    <Table
      aria-label="Quotes Table"
      isHeaderSticky
      isStriped
      topContent={topContent}
      topContentPlacement="outside"
      bottomContent={bottomContent}
      bottomContentPlacement="outside"
      sortDescriptor={sortDescriptor}
      onSortChange={setSortDescriptor}
      classNames={{
        td: "whitespace-nowrap",
      }}
    >
      <TableHeader columns={headerColumns}>
        {(column) => (
          <TableColumn
            key={column.uid}
            align={column.uid === "actions" ? "center" : "start"}
            allowsSorting={column.sortable}
          >
            {column.name}
          </TableColumn>
        )}
      </TableHeader>
      <TableBody
        emptyContent={"No quotes found"}
        items={sortedItems}
        isLoading={status === STATUS.LOADING && !quotes.length}
        loadingContent={
          <Spinner label="Loading..." className="w-full bg-white" />
        }
      >
        {(item) => (
          <TableRow key={item.id}>
            {(columnKey) => (
              <TableCell>
                {role === "SUPPLIER" ? (
                  <Link to={`/quotes/${item.id}`}>
                    {renderCell(item, columnKey)}
                  </Link>
                ) : role === "CUSTOMER" ? (
                  <Link to={`/updateStatus/${item.id}`}>
                    {renderCell(item, columnKey)}
                  </Link>
                ) : (
                  renderCell(item, columnKey)
                )}
              </TableCell>
            )}
          </TableRow>
        )}
      </TableBody>
    </Table>
  );
};

export default Quotes;
