export const columns = [
  { name: "Status", uid: "status", sortable: true },
  { name: "Customer", uid: "customer", sortable: true },
  { name: "Company", uid: "company", sortable: true },
  { name: "Car", uid: "car", sortable: true },
  { name: "Quantity", uid: "quantity", sortable: true },
  { name: "Total Price (Inc. GST)", uid: "total price", sortable: true },
  { name: "Created", uid: "created", sortable: true },
]; 

export const statusOptions = [
  { name: "all", uid: "all" },
  { name: "Awaiting Approval", uid: "awaiting approval" },
  { name: "progress", uid: "progress" },
  { name: "accepted", uid: "accepted" },
];

export const chipstyle = {
  1: "primary",
  2: "warning",
  3: "warning",
  4: "success",
  5: "success",
  6: "success",
  7: "danger",
  8: "danger",
};
