import React, { useState } from 'react';
import { Button, Input, Modal, ModalContent, ModalHeader, ModalBody } from "@nextui-org/react";
import { getAllSalesRep } from "../../store/slices/customerSlice";
import { useDispatch } from 'react-redux';
import axios from 'axios';

const AddAssignee = ({addAssignee, closeAssignee}) => {
  const dispatch = useDispatch();
    const [name, setName] = useState('');

    const addAssingee = async () => {
      let data = {
        SalesRepName: name
      }
      try {
        let token = localStorage.getItem("accessToken");
        let header = {
          headers: {
          authorization: `Bearer ${token}`,
          },
        };
        let res = await axios.post(`${process.env.REACT_APP_SERVER_URL}/suppliersalesreps/create`, data, header);
        dispatch(getAllSalesRep());
        closeAssignee();
      } catch (error) {
        const err = error.response.data.message;
        console.log(err);
      }
    }

  return (
    <Modal 
        size={"xs"} 
        isOpen={addAssignee} 
        onClose={closeAssignee}
        placement="center" 
      >
        <ModalContent>
          {(onClose) => (
            <>
              
              <ModalBody>
              <Input
                type="text"
                className="my-2"
                label="Add Sales Reprensentative"
                labelPlacement={"outside"}
                placeholder="Enter name"
                description={''}
                onChange={(event)=>setName(event.target.value)}
              /> 
              <div className="grid grid-cols-2 gap-2 pb-3">
                <Button className="border-2 border-orange-500 text-orange-500 bg-transparent hover:bg-orange-500 hover:text-white hover:border-none" onClick={()=>closeAssignee()}>Cancel</Button>
                <Button className="border-2 border-green-500 text-green-500 bg-transparent hover:bg-green-500 hover:text-white hover:border-none" onClick={()=>addAssingee()}>Submit</Button>
              </div>
              </ModalBody>
            </>
          )}
        </ModalContent>
      </Modal>
  )
}

export default AddAssignee