import React from 'react'
import { Link } from 'react-router-dom';

const Footer = () => {
  return (
    <div>
        <div className='p-3 text-center font-semibold text-sm border-t-1 border-gray-300'>
            <p className='text-blue-400'><Link to="/about-us">About Wreckonnect</Link></p>
        </div>
        <div className='bg-regalBlue text-white p-3 text-center text-xs leading-6 font-semibold border-t-1 border-gray-300'>
            <p>&copy; Copyright - All right reserved</p>
            <p>Created by <Link to="https://igtechso.com/" className='text-blue-400' target="_blank">Igtechso</Link></p>
        </div>
    </div>
  )
}

export default Footer;
