import React, { useEffect, useState } from "react";
import {
  Button,
  Chip,
  useDisclosure,
  Modal,
  ModalBody,
  ModalContent,
  ModalFooter,
  Select,
  SelectItem,
} from "@nextui-org/react";
import {
  getDisputeTitle,
  getCancelReason,
} from "../../store/slices/adminSlice";
import { Link, useNavigate } from "react-router-dom";
import { useForm } from "react-hook-form";
import { toast } from "react-toastify";
import axios from "axios";
import { useSelector, useDispatch } from "react-redux";

const OrderGenerate = ({ orderData, showButton }) => {
  const { register, handleSubmit } = useForm();
  const { disputeTitle, cancelReason } = useSelector((state) => state.admin);
  const role = localStorage.getItem("role");
  const {
    isOpen: disputeOpen,
    onOpen: onDispute,
    onClose: closeDispute,
  } = useDisclosure();
  const {
    isOpen: cancelModal,
    onOpen: onCancel,
    onClose: closeModal,
  } = useDisclosure();
  const [supplier, setSupplier] = useState();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [multipleOffer, setMultipleOffer] = useState([]);

  const onSubmit = (data) => {
    const { title } = data;
    closeDispute();
    let reason_data = {
      title: title,
      titleReason: disputeTitle.filter((item) => item.id === title)[0]?.name,
      make: orderData?.Request?.VehicleMake?.name
        ? orderData?.Request?.VehicleMake?.name
        : orderData?.Request?.custom_make,
      model: orderData?.Request?.VehicleModel?.name
        ? orderData?.Request?.VehicleModel?.name
        : orderData?.Request?.custom_model,
      part: orderData?.Request?.Part?.name
        ? orderData?.Request?.Part?.name
        : orderData?.Request?.custom_part_type,
    };
    localStorage.setItem("reason_data", JSON.stringify(reason_data));
    setTimeout(() => {
      navigate(`/dispute-details/${orderData?.id}`);
    }, 700);
  };

  const handleCancel = async (data) => {
    try {
      let token = localStorage.getItem("accessToken");
      let res = await axios.patch(
        `${process.env.REACT_APP_SERVER_URL}/orders/${orderData.id}/cancel`,
        data,
        {
          headers: {
            authorization: `Bearer ${token}`,
          },
        }
      );
      toast.success(res.data.message);
      closeModal();
      window.location.reload();
    } catch (error) {
      let err = error.response?.data?.message;
      toast.error(err);
    }
  };

  const getSupplierInfo = async (id) => {
    if (id) {
      try {
        let res = await axios.get(
          `${process.env.REACT_APP_SERVER_URL}/users/profile/${id}`
        );
        let data = res.data?.details?.user;
        setSupplier(data);
      } catch (error) {
        let err = error.response.data.message;
        toast.error(err);
      }
    }
  };

  useEffect(() => {
    dispatch(getDisputeTitle());
    dispatch(getCancelReason());
  }, [dispatch]);


  useEffect(() => {
    if (role === "CUSTOMER") {
      if (orderData) {
        getSupplierInfo(orderData.supplier_id);
        if (orderData?.Quote?.QuoteOptions?.length > 0) {
          let offers = orderData.Quote.QuoteOptions;
          let trueOffers = offers.filter((offers) => offers.approval === true);
          setMultipleOffer(trueOffers);
        }
      }
    }
  }, [orderData]);


  return (
    <div>
      {role === "CUSTOMER" && (
        <div className="mt-7">
          <p className="font-semibold pb-2 text-base border-b-1 border-gray-300 ">
            Supplier details
          </p>
          <div className="pt-3">
            <div className="grid sm:grid-cols-4 items-center">
              <div className="sm:block flex justify-center mb-3 sm:mb-0">
                <img
                  className="rounded-full w-28 sm:w-40 h-28 sm:h-40 text-center"
                  src={supplier?.profile_id}
                  alt="supplier"
                />
              </div>
              <div className="sm:col-span-3 text-xs sm:text-sm">
                <div className="grid gap-4 grid-cols-2 justify-between [&>p>span:first-child]:font-semibold">
                  <p>
                    <span>Name: </span>
                    <span>
                      {supplier?.first_name + " " + supplier?.last_name}
                    </span>
                  </p>
                  <p>
                    <span>Phone Number: </span>
                    <span>{supplier?.phone}</span>
                  </p>
                  <p>
                    <span>Email: </span>
                    <span></span>
                    {supplier?.email}
                  </p>
                  <p>
                    <span>Company Name: </span>
                    <span>{supplier?.company_name}</span>
                  </p>
                </div>
                <p className="mt-4">
                  <span className="font-semibold">Address:</span>
                  <span>{supplier?.address_line_1}</span>{" "}
                </p>
              </div>
            </div>
          </div>
        </div>
      )}

      <div className="mt-7">
        <p className="font-semibold pb-2 text-base border-b-1 border-gray-300 ">
          Your order details
        </p>
        <div className="my-7 border-1 border-gray-300 rounded-lg md:grid md:grid-cols-5 shadow-inner">
          <div className="col-span-4 border-b-1 sm:border-r-1 border-gray-200">
            <div className="flex justify-between items-center py-2 md:py-3 px-3 md:px-5 border-b-1 border-gray-200">
              <p className="text-sm font-semibold">
                <span>Tracking Number : </span>
                <span className="text-red-500">
                  {orderData?.tracking_number}
                </span>
              </p>
              <Chip
                color="success"
                variant="dot"
                className="border-2 border-green-400 text-green-400"
              >
                {orderData?.OrderStatus?.status}
              </Chip>
            </div>
            <div className="p-7 sm:p-3 md:p-5">
              <div className="grid sm:grid-cols-3 gap-1 ">
                <div className="!mt-0 order-generation-text">
                  <p>
                    <span>Make : </span>
                    <span>
                      {orderData?.Request?.VehicleMake?.name
                        ? orderData?.Request?.VehicleMake?.name
                        : orderData?.Request?.custom_make}
                    </span>
                  </p>
                  <p>
                    <span>Modal : </span>
                    <span>
                      {orderData?.Request?.VehicleModel?.name
                        ? orderData?.Request?.VehicleModel?.name
                        : orderData?.Request?.custom_model}
                    </span>
                  </p>
                  <p>
                    <span>Part : </span>
                    <span>
                      {orderData?.Request?.Part?.name
                        ? orderData?.Request?.Part?.name
                        : orderData?.Request?.custom_part_type}
                    </span>
                  </p>
                  <p>
                    <span>Quantity : </span>
                    <span>{orderData?.Request?.quantity} Qnty</span>
                  </p>
                  <p>
                    <span>Rego : </span>
                    <span>{orderData?.Request?.rego}</span>
                  </p>
                  <p>
                    <span>Fuel Type : </span>
                    <span>{orderData?.Request?.fuelType.name}</span>
                  </p>
                  <p>
                    <span>Sales Rep : </span>
                    <span>
                      {orderData?.Quote?.SupplierSalesRep?.SalesRepName}
                    </span>
                  </p>
                </div>
                <div className="order-generation-text">
                  <p>
                    <span>Part Price : </span>
                    <span>${orderData?.Quote?.part_price}</span>
                  </p>
                  <p>
                    <span>Freight Price : </span>
                    <span>${orderData?.Quote?.freight_price}</span>
                  </p>
                  <p>
                    <span>Total Price (Inc GST) : </span>
                    <span>${orderData?.Quote?.total_price}</span>
                  </p>
                  <p>
                    <span>Warranty : </span>
                    <span>
                      {orderData?.Quote?.warrantyDuration?.duration}{" "}
                      {orderData?.Quote?.warrantyDuration?.unit}
                    </span>
                  </p>
                  <p>
                    <span>Warranty Price : </span>
                    <span>
                      $ {orderData?.Quote?.warranty_price
                        ? orderData?.Quote?.warranty_price
                        : "Not provided"}
                    </span>
                  </p>
                  <p>
                    <span>Warranty Option : </span>
                    <span>
                      {orderData?.Quote?.warranty_option
                        ? orderData?.Quote?.warranty_option
                        : "Not provided"}
                    </span>
                  </p>

                </div>
                <div className="order-generation-text">
                  <p>
                    <span>Transmission : </span>
                    <span>{orderData?.Request?.transmissionModel.name}</span>
                  </p>
                  <p>
                    <span>Tracking Carrier : </span>
                    <span>{orderData?.tracking_carrier}</span>
                  </p>
                  <p>
                    <span>Shipping Date : </span>
                    <span>{orderData?.date_ship?.split("T")[0]}</span>
                  </p>
                  <p>
                    <span>Order Date : </span>
                    <span>{orderData?.date_ordered?.split("T")[0]}</span>
                  </p>
                  <p>
                    <span>Body Style : </span>
                    <span>{orderData?.Request?.bodyStyle?.name}</span>
                  </p>
                </div>
              </div>
              <div className="mt-2 [&>p>span]:text-xs text-center sm:text-left md:[&>p>span]:text-sm [&>p>span:first-child]:font-semibold leading-8">
                <p>
                  <span>Shipping Address : </span>
                  <span>{orderData?.Quote?.shipping_address}</span>
                </p>
                <p>
                  <span>Notes : </span>
                  <span>{orderData?.notes}</span>
                </p>
              </div>
            </div>
          </div>

          <div className="font-semibold flex  justify-center items-center ">
            {orderData?.OrderStatus?.status === "Dispute" ? (
              <div className="text-sm font-semibold text-red-500 p-2">
                Order disputed
              </div>
            ) : orderData?.OrderStatus?.status === "Cancelled" ? (
              <Button className="bg-lightBlue text-white px-10">
                {role === 'CUSTOMER' ? "Request Credit" : "Initiate Refund"}
              </Button>

            ) : (
              <div className="flex flex-col sm:flex-row md:flex-col gap-3 justify-center py-4 md:py-0 font-semibold">
                {showButton && (
                  <Link to={`/orders/${orderData.id}`}>
                    <Button className="bg-lightBlue text-white px-10">
                      Check Order
                    </Button>
                  </Link>
                )}
                <Button
                  className="bg-purpleBtn text-white px-9"
                  onClick={() => onDispute()}
                >
                  Dispute Order
                </Button>
                <Button
                  className="bg-lightBlue text-white px-10"
                  onClick={() => onCancel()}
                >
                  Cancel Order
                </Button>
              </div>
            )}
          </div>
        </div>

        {multipleOffer.length > 0 && (
          <div className="my-7 border-1 border-gray-300 rounded-lg  shadow-inner">
            <div className=" border-b-1 sm:border-r-1 border-gray-200">
              <div className="flex justify-between items-center py-2 md:py-3 px-3 md:px-5 border-b-1 border-gray-200">
                <p className="text-sm font-semibold">
                  <p>Additional Offers:</p>
                </p>
              </div>
              {
                multipleOffer.map((e) => (
                  <div className="p-7 sm:p-3 md:p-5">
                    <div className="grid sm:grid-cols-4 gap-1 ">
                      <div className="!mt-0 order-generation-text">
                        <p>
                          <span>
                            {e?.Part?.name ? "Part : " : "Custom part : "}
                          </span>
                          <span>
                            {e?.Part?.name
                              ? e?.Part?.name
                              : e?.custom_part_type}
                          </span>
                        </p>
                        <p>
                          <span>Quantity : </span>
                          <span>{e?.quantity} Qnty</span>
                        </p>
                      </div>
                      <div className="!mt-0 order-generation-text">
                        <p>
                          <span>Part Price : </span>
                          <span>${e?.part_price}</span>
                        </p>
                        <p>
                          <span>Freight Price : </span>
                          <span>${e?.freight_price}</span>
                        </p>
                      </div>
                      <div className="!mt-0 order-generation-text">
                        <p>
                          <span>Warranty : </span>
                          <span>
                            {e?.warrantyDuration?.duration}{" "}
                            {e?.warrantyDuration?.unit}
                          </span>
                        </p>

                        <p>
                          <span>Warranty Option : </span>
                          <span>
                            {e?.warranty_option
                              ? e?.warranty_option
                              : "Not provided"}
                          </span>
                        </p>
                      </div>
                      <div className="order-generation-text">
                        <p>
                          <span>Warranty Price : </span>
                          <span>
                            ${e?.warranty_price ? e?.warranty_price : "0"}
                          </span>
                        </p>
                        <p>
                          <span>Total Price (Inc GST) : </span>
                          <span>${e?.total}</span>
                        </p>
                      </div>
                    </div>
                  </div>
                ))
              }
            </div>
          </div>
        )}

        {/* ----- modal for dispute ---- */}
        <Modal
          size={"sm"}
          isOpen={disputeOpen}
          onClose={closeDispute}
          className="p-3"
          placement="center"
        >
          <ModalContent>
            {(closeDispute) => (
              <>
                <form onSubmit={handleSubmit(onSubmit)}>
                  <ModalBody className="w-full">
                    <p className="text-base font-semibold text-center">
                      Are you sure?
                    </p>
                    <Select
                      labelPlacement={"outside"}
                      variant={"bordered"}
                      label="Select the Reason"
                      placeholder="Select reason"
                      className="w-full"
                      {...register("title", { required: true })}
                    >
                      {disputeTitle?.map(
                        (item) =>
                          item?.id !== 0 && item?.id !== -1 && (
                            <SelectItem key={item.id}>{item.name}</SelectItem>
                          )
                      )}
                    </Select>
                  </ModalBody>
                  <ModalFooter className="p-3 mt-4 justify-normal">
                    <div className="grid grid-cols-2 gap-4 w-full">
                      <Button
                        className="w-full bg-purpleBtn font-semibold text-white"
                        onClick={closeDispute}
                      >
                        Close
                      </Button>
                      <Button
                        type="submit"
                        className="w-full bg-lightBlue font-semibold text-white"
                      >
                        Submit
                      </Button>
                    </div>
                  </ModalFooter>
                </form>
              </>
            )}
          </ModalContent>
        </Modal>
        {/* ----- modal for cancel ---- */}
        <Modal
          size={"sm"}
          isOpen={cancelModal}
          onClose={closeModal}
          className="p-3"
          placement="center"
        >
          <ModalContent>
            {(closeModal) => (
              <>
                <form onSubmit={handleSubmit(handleCancel)}>
                  <ModalBody className="w-full !px-3">
                    <p className="text-base font-semibold text-center">
                      Are you sure?
                    </p>
                    <Select
                      labelPlacement={"outside"}
                      variant={"bordered"}
                      label="Select the Reason"
                      placeholder="Select reason"
                      className="w-full"
                      {...register("cancel_reason", { required: true })}
                    >
                      {cancelReason?.map(
                        (item) =>
                          item?.id !== 0 && (
                            <SelectItem key={item.id}>
                              {item.name}
                            </SelectItem>
                          )
                      )}
                    </Select>
                  </ModalBody>
                  <ModalFooter className="p-3 mt-4 justify-normal">
                    <div className="grid grid-cols-2 gap-4 w-full">
                      <Button
                        className="w-full bg-purpleBtn font-semibold text-white"
                        onClick={closeModal}
                      >
                        Cancel
                      </Button>
                      <Button
                        type="submit"
                        className="w-full bg-lightBlue font-semibold text-white"
                      >
                        Submit
                      </Button>
                    </div>
                  </ModalFooter>
                </form>
              </>
            )}
          </ModalContent>
        </Modal>
      </div>
    </div>
  );
};

export default OrderGenerate;
