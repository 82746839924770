import React from 'react';
import { Link, useLocation } from "react-router-dom";

const BreadCrumb = () => {
    const {pathname} = useLocation();

    let path_arr = pathname.split("/");
    path_arr.shift();

    let path_url = []; // for making url
    let url = '/';

    for(let i=0; i<path_arr.length; i++){
        if(path_arr[i].length>0){
            url = url + path_arr[i] + "/";
            path_url.push(url);
        }
    }

    path_arr = path_arr.filter((str) => str !== '');

  return (
    <div className="flex items-center gap-1 breadcrumb [&>*:first-child]:font-semibold [&>*:first-child]:text-[0.7rem] md:[&>*:first-child]:text-base [&>*:last-child]:pt-[7px] sm:[&>*:last-child]:pt-[3px] [&>*:last-child]:after:hidden">
        {path_arr.map((item, index)=>
            <div className="capitalize mr-2 sm:mr-3 text-[0.7rem] sm:text-sm after:content-['/'] after:relative after:top-[1px] after:left-[9px] after:text-lg after:font-semibold after:text-blue-950" key={index}>
                <Link to={`${path_url[index]==="/updateStatus/" || path_url[index]==="/accept-quote/" || path_url[index]==="/accept-order/" ? "/quotes" : path_url[index]==="/dispute-details/" ? "/support" : path_url[index] === "/requestDetails/" ? "/requests" : path_url[index] === "/updateprofile/" ?"/users" : path_url[index] === "/disputes/" ? "/users" : path_url[index]==="/disputes/" ? "/users" : path_url[index]}`}>{item.length>0?item:""}</Link>
            </div>
        )}
    </div>
  )
}

export default BreadCrumb;
