import React, { useEffect,useState } from "react";
import {
  chipValue,
  requestStyle,
  quoteChipColor,
  chipColor,
  chipStyle,
} from "../data/styleData";
import { Button, Chip,User } from "@nextui-org/react";
import { useSelector } from "react-redux";
import UpdateLayout from "./UpdateLayout";
import { toast } from "react-toastify";
import axios from "axios";

const AcceptQuoteLayout = ({ handleOrder }) => {
  const role = localStorage.getItem("role");
  const customers = JSON.parse(localStorage.getItem("customer_request"))
  const accessToken = localStorage.getItem("accessToken");
  const [customerDetails,setCustomerDetails] =useState([]);
  const {
    supplier_id,
    quote,
    reps,
    orderDetails,
    singleRequest,
    user,
    quote_images,
  } = useSelector((state) => state.customer);

  // console.log(singleRequest)
 const getCustomerDetails=async()=>{
  try{
    let res=await axios.get(`${process.env.REACT_APP_SERVER_URL}/users/profile/${singleRequest?.customer_id}`,
        {},
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${accessToken}`,
          },
        })
        setCustomerDetails(res.data?.details)

        // console.log(res.data);
  }
  catch(error){
    console.log(error)
  }
 }



  const handlePayment = async () => {
    try {
      let res = await axios.post(
        `${process.env.REACT_APP_SERVER_URL}/payment/registerandcheckout/${quote?.id}`,
        {},
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${accessToken}`,
          },
        }
      );
      const paymentLink = res.data.paymentLink;
      
      window.open(paymentLink, "_blank");
    } catch (error) {
      const err = error?.response?.data?.message;
      toast.error(err);
    }
  };
  
  useEffect(() => {
    getCustomerDetails();
    if (orderDetails) {
      let status = orderDetails?.OrderStatus?.status;
      if (status === "Dispute") {
        toast.error("Your order has been disputed!");
      }
    } else {
      if (role === "CUSTOMER") {
        toast.info("Order is not generated by supplier");
      }
    }
  }, [supplier_id, quote, orderDetails, singleRequest,user, quote_images]);
// console.log(user)
  return (
    <div>
      {quote?.transections?.[0]?.payment_status === "paid" ? (
        <Chip
          color={chipColor["Accepted"]} // Assuming "Accepted" corresponds to green color
          variant="dot"
          className="float-right text-green-400 border-[2px] border-green-400"
        >
          <p>Paid</p>
        </Chip>
      ) : (
        <Chip
          color={chipColor["Rejected"]}
          variant="dot"
          className="float-right text-red-400 border-[2px] border-red-400"
        >
          <p>Unpaid</p>
        </Chip>
      )}

      <UpdateLayout
        heading={"Accepted Quote"}
        title={""}
        images={quote_images}
        requestId={quote?.request_id}
        quoteId={quote?.id}
      >
        {/* --------customer details------- */}
        {singleRequest?.RequestStatus?.status==="Accepted"  &&   <div className="mb-5">
          <p className="font-semibold">Customer Details</p>
          <div className="flex gap-4 mt-8">
            <User
              // name={customerDetails?.user?.first_name}
              description=""
              avatarProps={{
                src: `${customerDetails?.user?.profile_id}`,
              }}
              className="font-semibold"
            />
            <div>
              <p>{customerDetails?.user?.first_name} {customerDetails?.user?.last_name}</p>
              <p className="text-sm">{customerDetails?.user?.email}</p>
            </div>
          <p>{customerDetails?.user?.capricorn_number && "(Capricorn Member)"}</p>
          </div>
          <div className="grid grid-cols-4 gap-2 [&>div]:text-center [&>div]:text-[0.8rem] [&>div>p:first-child]:font-semibold [&>div>p:first-child]:text-[0.9rem] mt-7">
           
            <div>
              <p>Phone no.</p>
              <p>{customerDetails?.user?.phone}</p>
            </div>
            <div>
              <p>City</p>
              <p>{customerDetails?.user?.suburb}</p>
            </div>
            
            <div>
              <p>State</p>
              <p>{customerDetails?.user?.state}</p>
            </div>
            <div>
              <p>Postcode</p>
              <p>{customerDetails?.user?.postcode}</p>
            </div>
            
          </div>
        </div>}
      

        {/* --------request details------- */}
        <div>
          <div className="flex justify-between items-center">
            <p className="font-semibold">Request Details</p>
            <Chip
              color={chipColor[singleRequest?.RequestStatus?.status]}
              variant="dot"
              className={chipStyle[singleRequest?.RequestStatus?.status]}
            >
              {<p>{singleRequest?.RequestStatus?.status}</p>}
            </Chip>
          </div>
          <div className="grid grid-cols-2 sm:grid-cols-4 gap-2 [&>div]:text-center [&>div]:text-[0.8rem] [&>div>p:first-child]:font-semibold [&>div>p:first-child]:text-[0.9rem] mt-7">
            <div>
              <p>Vehicle Make</p>
              <p>
                {singleRequest?.VehicleMake?.name
                  ? singleRequest?.VehicleMake?.name
                  : singleRequest?.custom_make}
              </p>
            </div>
            <div>
              <p>Vehicle Mode</p>
              <p>
                {singleRequest?.VehicleModel?.name
                  ? singleRequest?.VehicleModel?.name
                  : singleRequest?.custom_model}
              </p>
            </div>
            <div>
              <p>Vehicle Part</p>
              <p>
                {singleRequest?.Part
                  ? singleRequest?.Part?.name
                  : singleRequest?.custom_part_type}
              </p>
            </div>
            <div>
              <p>Quantity</p>
              <p>{singleRequest?.quantity} Qnty</p>
            </div>
            <div>
              <p>Rego</p>
              <p>{singleRequest?.rego}</p>
            </div>
            <div>
              <p>Fuel Type</p>
              <p>{singleRequest?.fuelType?.name}</p>
            </div>
            <div>
              <p>Transmission</p>
              <p>{singleRequest?.transmissionModel?.name}</p>
            </div>
            <div>
              <p>Body Style</p>
              <p>{singleRequest?.bodyStyle?.name}</p>
            </div>
            <div className="col-span-2 sm:col-span-4 !text-left ml-4">
              <span className="text-[0.9rem] font-semibold text-left">
                Interchange Number:{" "}
              </span>
              <span>
                {singleRequest?.interchange_number
                  ? singleRequest?.interchange_number
                  : "Not Provided"}
              </span>
            </div>
            <div className="col-span-2 sm:col-span-4 !text-left ml-4">
              <span className="text-[0.9rem] font-semibold text-left">
                Note:{" "}
              </span>
              <span>{singleRequest?.notes}</span>
            </div>
          </div>
        </div>
        {/* -------quote details------- */}
        <div>
          <div className="flex justify-between mt-10">
            <p className="font-semibold">Quote Details:</p>
            <Chip
              color={quoteChipColor[quote?.status]}
              variant="dot"
              className={requestStyle[quote?.status]}
            >
              <p>{chipValue[quote?.status]}</p>
            </Chip>
          </div>
          <div className="grid grid-cols-3 gap-2 [&>div]:text-center [&>div]:text-[0.8rem] [&>div>p:first-child]:font-semibold [&>div>p:first-child]:text-[0.9rem] mt-7">
            <div>
              <p>Part Price</p>
              <p>${quote?.part_price}</p>
            </div>
            <div>
              <p>Freight Price</p>
              <p>${quote?.freight_price}</p>
            </div>
            <div>
              <p>Total Price</p>
              <p>${quote?.total_price}</p>
            </div>
            <div>
              <p>Sales Rep</p>
              <p>{quote?.SupplierSalesRep?.SalesRepName}</p>
            </div>
            
              <div>
                <p>Warranty</p>
                <p>
                  {quote?.warrantyDuration?.duration}{" "}
                  {quote?.warrantyDuration?.unit}
                </p>
              </div>
        
              <div>
                <p>Warranty Option</p>
                <p>{quote?.warranty_option ? quote?.warranty_option : "Not Provided"}</p>
              </div>
         
        
              <div>
                <p>Warranty Price</p>
                <p>${quote?.warranty_price ? quote?.warranty_price : "0"}</p>
              </div>
          
            <div>
              <p>Shipping Date</p>
              <p>{quote?.shipping_date?.split("T")[0]}</p>
            </div>
           
            <div className="col-span-3 ml-4">
              <span className="text-[0.9rem] font-semibold">Note: </span>
              <span>{quote?.notes}</span>
            </div>
          </div>
        </div>
        <div className="mt-7">
            {role === "SUPPLIER" ? (
              <Button
                className="w-full border-2 bg-transparent hover:bg-lightBlue hover:text-white font-semibold border-lightBlue text-lightBlue"
                onClick={handleOrder}
              > 
                {quote?.orderDetails?.OrderStatus?.status ==="Initiated" ? "Order already exist" :"Generate Order"}
              </Button>
            ) : (
              quote?.transections?.[0]?.payment_status !== "paid" && (
                <Button
                  className="w-full border-2 bg-transparent hover:bg-lightBlue hover:text-white font-semibold border-lightBlue text-lightBlue"
                  onClick={handlePayment}
                >
                  Checkout for Payment{" "}
                </Button>
              )
            )}
          </div>
      </UpdateLayout>
    </div>
  );
};

export default AcceptQuoteLayout;
