import React, { useEffect, useMemo, useState } from "react";
import {
  Table,
  TableHeader,
  TableColumn,
  TableBody,
  TableRow,
  TableCell,
  Input,
  Chip,
  Pagination,
  Spinner,
} from "@nextui-org/react";
import { FiSearch } from "react-icons/fi";
import { useNavigate } from "react-router-dom";
import { displayDate, formatTime } from "../utils";
import { columns, statusOptions } from "../data/disputesData";
import { changeStatus, getAllDisputes } from "../store/slices/supplierSlice";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import axios from "axios";
import { STATUS } from "../store/constants";

const INITIAL_VISIBLE_COLUMNS = ["status", "car", "part", "created"];

const Disputes = () => {
  const accessToken = localStorage.getItem("accessToken");
  const dispatch = useDispatch();
  const navigate=useNavigate();
  const { status } = useSelector((state) => state.supplier);
  const [disputes, setDisputes] = useState([]);
  const [filterValue, setFilterValue] = React.useState("");
  const [visibleColumns] = React.useState(new Set(INITIAL_VISIBLE_COLUMNS));
  const [statusFilter] = React.useState("all");
  const [rowsPerPage, setRowsPerPage] = React.useState(6);
  const [sortDescriptor] = React.useState({
    column: "name",
    direction: "ascending",
  });
  const [page, setPage] = React.useState(1);
  const [totalPages, setTotalPages] = useState(0);
  const [totalRecords, setTotalRecords] = useState('');

  const hasSearchFilter = Boolean(filterValue);

  const headerColumns = React.useMemo(() => {
    if (visibleColumns === "all") return columns;

    return columns.filter((column) =>
      Array.from(visibleColumns).includes(column.uid)
    );
  }, [visibleColumns]);

  const filteredItems = useMemo(() => {
    let filteredData = [...disputes];

    // search-filter
    if (hasSearchFilter) {
      filteredData = filteredData.filter(
        (data) =>
          data?.Order?.Request?.VehicleMake.name
            .toLowerCase()
            .includes(filterValue.toLowerCase()) ||
          data?.Order?.Request?.VehicleModel?.name
            .toLowerCase()
            .includes(filterValue.toLowerCase()) ||
          data?.Order?.Request?.Part?.name
            .toLowerCase()
            .includes(filterValue.toLowerCase())
      );
    }
    // status-filter
    if (
      statusFilter !== "all" &&
      Array.from(statusFilter).length !== statusOptions.length
    ) {
      filteredData = filteredData.filter((data) =>
        statusFilter.includes(data?.QuoteStatus?.status.toLowerCase())
      );
    }

    return filteredData;
  }, [disputes, hasSearchFilter, statusFilter, filterValue]);

  const pages = totalPages;

  const items = React.useMemo(() => {
    return filteredItems;
  }, [page, filteredItems, rowsPerPage]);

  const fetchData = async (page, pageSize) => {
    try {
      dispatch(changeStatus(STATUS.LOADING));
      setDisputes([]);
      const res = await axios.get(
        `${process.env.REACT_APP_SERVER_URL}/disputes/?page=${page}&pageSize=${pageSize}`,
        {
          headers: {
            authorization: `Bearer ${accessToken}`,
          },
        }
      );
      let data = res.data.details?.rows;
      let index = res.data?.details?.index;
      data.sort((a, b) => {
        let date1 = new Date(a?.createdAt);
        let date2 = new Date(b?.createdAt);
        return date2 - date1;
      });
      setDisputes(data);
      setTotalPages(index?.totalPages);
      setTotalRecords(index?.totalRecords);
      dispatch(changeStatus(STATUS.IDLE));
    } catch (error) {
      dispatch(changeStatus(STATUS.ERROR));
      console.log(error);
    }
  }

  // getting all quotes
  useEffect(() => {
    fetchData(page, rowsPerPage);
  }, [dispatch, accessToken, page, rowsPerPage]);

  const sortedItems = React.useMemo(() => {
    return [...items].sort((a, b) => {
      const first = a[sortDescriptor.column];
      const second = b[sortDescriptor.column];
      const cmp = first < second ? -1 : first > second ? 1 : 0;

      return sortDescriptor.direction === "descending" ? -cmp : cmp;
    });
  }, [sortDescriptor, items]);

  const renderCell = React.useCallback((data, columnKey) => {
    const cellValue = data[columnKey];

    switch (columnKey) {
      case "status":
        return (
          <Chip className="capitalize" color={"warning"} variant="flat">
            Disputed
          </Chip>
        );

      case "car":
        return (
          <div>
            <p className="table_cell">
              {data?.Order?.Request?.VehicleMake?.name ? data?.Order?.Request?.VehicleMake?.name : data?.Order?.Request?.custom_make}{" "}
              {data?.Order?.Request?.VehicleModel?.name ? data?.Order?.Request?.VehicleModel?.name :data?.Order?.Request?.custom_model }
            </p>
          </div>
        );

      case "part":
        return (
          <div>
            <p className="table_cell">{data?.Order?.Request?.Part?.name ? data?.Order?.Request?.Part?.name : data?.Order?.Request?.custom_part_type}</p>
          </div>
        );

      case "created":
        return (
          <div className="flex flex-col gap-1">
            <p className="table_cell normal-case">
              {formatTime(data?.createdAt)}
            </p>
            <p className="table_cell_sm">{displayDate(data?.createdAt)}</p>
          </div>
        );

      default:
        return cellValue;
    }
  }, []);

  const onRowsPerPageChange = React.useCallback((e) => {
    setRowsPerPage(Number(e.target.value));
    setPage(1);
    fetchData(1, e.target.value);
  }, []);

  const onSearchChange = React.useCallback((value) => {
    if (value) {
      setFilterValue(value);
    } else {
      setFilterValue("");
    }
  }, []);

  const onClear = React.useCallback(() => {
    setFilterValue("");
  }, []);

  // top content
  const topContent = React.useMemo(() => {
    return (
      <div className="flex flex-col gap-4">
        <div className="flex justify-between gap-3 items-end">
          <Input
            isClearable
            className="w-full sm:max-w-[44%]"
            placeholder="Search disputes..."
            startContent={<FiSearch />}
            value={filterValue}
            onClear={() => onClear()}
            onValueChange={onSearchChange}
          />
        </div>

        <div className="flex justify-between items-center text-xs sm:text-sm">
          <span className="text-default-400">
            Total: {totalRecords} disputes
          </span>
          <label className="flex items-center text-default-400">
            Rows per page:
            <select
              className="bg-transparent outline-none text-default-400"
              onChange={onRowsPerPageChange}
            >
              <option value="6">6</option>
              <option value="10">10</option>
              <option value="15">15</option>
            </select>
          </label>
        </div>
      </div>
    );
  }, [
    filterValue,
    onClear,
    onRowsPerPageChange,
    disputes.length,
    onSearchChange,
  ]);

  const bottomContent = React.useMemo(() => {
    return (
      <div className="py-2 px-2 flex justify-center items-center">
        <Pagination
          isCompact
          showControls
          showShadow
          color="primary"
          page={page}
          total={pages === 0 ? pages + 1 : pages}
          onChange={setPage}
        />
      </div>
    );
  }, [page, pages]);

  return (
    <Table
      aria-label="Disputes Table"
      isHeaderSticky
      isStriped
      bottomContent={bottomContent}
      bottomContentPlacement="outside"
      sortDescriptor={sortDescriptor}
      topContent={topContent}
      topContentPlacement="outside"
      classNames={{
        td: "whitespace-nowrap",
      }}
    >
      <TableHeader columns={headerColumns}>
        {(column) => (
          <TableColumn
            key={column.uid}
            align={column.uid === "actions" ? "center" : "start"}
          >
            {column.name}
          </TableColumn>
        )}
      </TableHeader>
      <TableBody
        emptyContent={"No disputes found"}
        items={sortedItems}
        isLoading={status === STATUS.LOADING && !disputes.length}
        loadingContent={
          <Spinner label="Loading..." className="w-full bg-white" />
        }
      >
        {(item) => (
          <TableRow key={item.id} onClick={() =>navigate(`/support/${item.id}`)}>
            {(columnKey) => (
              <TableCell>
                <Link to={`/disputes/${item.id}`}>
                  {renderCell(item, columnKey)}
                </Link>
              </TableCell>
            )}
          </TableRow>
        )}
      </TableBody>
    </Table>
  );
};

export default Disputes;
