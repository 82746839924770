import React from "react";
import AuthLayout from "../../layouts/AuthLayout";
import { Button, Input } from "@nextui-org/react";
import { Link } from "react-router-dom";
import axios from "axios";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import CustomButton from "../CustomButton";
import { forgotPassSchema } from "./validationSchemas";
import { toast } from "react-toastify";

const ForgotPassword = () => {
  const {
    register,
    handleSubmit,
    formState: { errors },
    reset,
  } = useForm({
    resolver: yupResolver(forgotPassSchema),
  });

  const onSubmit = async (data) => {
    try {
      const response = await axios.post(
        `${process.env.REACT_APP_SERVER_URL}/users/forgotPassword`,
        data
      );
      if (response.status === 200) {
        toast.success(
          "A reset password link has been sent to your registered email"
        );
      }
      reset();
    } catch (error) {
      let err = error?.response?.data?.message;
      toast.error(err);
    }
  };

  return (
    <AuthLayout>
      <form className="xl:px-10" onSubmit={handleSubmit(onSubmit)} noValidate>
        <div className="text-center">
          <h2 className="md:text-3xl text-2xl">Forgot Password?</h2>
        </div>
        <div className="mt-12 mb-8">
          <Input
            {...register("email")}
            type="email"
            variant="bordered"
            label="Email"
            labelPlacement="outside"
            placeholder=" "
            isRequired
            errorMessage={errors.email?.message}
          />
        </div>
        <div>
          <CustomButton text="Send Reset Link" />
        </div>
        <div className="text-sm mt-12 space-y-1 [&>div]:flex [&>div]:justify-center [&>div]:items-center [&>div]:gap-2 [&>div]:mt-1">
          <div>
            <p>Already have an account?</p>
            <Link to="/login" className="auth_link_2">
              Login
            </Link>
          </div>
          
        </div>
        <div className="text-sm text-center mt-4">
            <p>Don't have an account?</p>
            <div className="flex gap-2 items-center justify-center mt-1">
              <div className="w-12 border-[0.5px] border-purpleBt"></div>
              <div>Sign Up As</div>
              <div className="w-12 border-[0.5px] border-purpleBt"></div>
            </div>
            <div className="flex gap-3 justify-center mt-2">
              <Link to='/signup/CUSTOMER'><Button className="bg-transparent border-2 border-lightBlue text-lightBlue hover:bg-lightBlue hover:border-none hover:text-white px-4">CUSTOMER</Button></Link>
              <Link to='/signup/SUPPLIER'><Button className="bg-transparent border-2 border-lightBlue text-lightBlue hover:bg-lightBlue hover:border-none hover:text-white px-[22px]">SUPPLIER</Button></Link>
            </div>
          </div>
      </form>
    </AuthLayout>
  );
};
 
export default ForgotPassword;
