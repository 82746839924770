import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import {
  Button,
  Badge,
  Popover,
  ScrollShadow,
  PopoverContent,
  PopoverTrigger,
} from "@nextui-org/react";
import { BiBell } from "react-icons/bi";
import { io } from "socket.io-client";
import { formatTime } from "../utils";
import { Link } from "react-router-dom";


const roleLink = {
  "QUOTE":"/updateStatus/",
  "QUOTES":"/accept-order/",
  "DISPUTE":"/support/",
  "ORDER":"/orders/"
}
 
const Notification = () => {
  const accessToken = localStorage.getItem("accessToken");
  const { user } = useSelector((state) => state.auth);
  const [socket, setSocket] = useState(null);
  const [notifications, setNotifications] = useState([]);
  const [isNotificationOpen, setIsNotificationOpen] = useState(false);

  const handleClick = async (id) => {
    setIsNotificationOpen(false);
    socket.on("deleteNotificationSuccess", (message) => {
      const data = notifications.filter((item)=>{
        return item.id!==message.messageId;
      })
      setNotifications(data);
    });

    socket.on("deleteNotificationNotFound", (message) => {
      console.log(message);
    });

    socket.on("deleteNotificationError", (message) => {
      console.log(message);
    });

    socket.emit("delete notification", {
      "id": id,
    });
  }

  // fetching Notifications
  useEffect(() => {
    if (user?.id) {
      const newSocket = io(
        `${process.env.REACT_APP_SOCKET_SERVER_URL}/notifications/${user?.id}`,
        {
          extraHeaders: {
            Authorization: `Bearer ${accessToken}`,
          },
        }
      );
      setSocket(newSocket);
      // Listen for getting previous chats
      newSocket.on("previous notification", (notification) => {
        setNotifications(notification);
      });

      // Listen for getting notifications
      newSocket.on("notification", (notification) => {
        setTimeout(()=>{
          setNotifications((prevNotifications) => [notification, ...prevNotifications,]);
        },1000);
      });

      return () => {
        newSocket.disconnect();
      };
      
    }
  }, [accessToken, user?.id]);

  useEffect(()=>{},[notifications])

  return (
    <Popover
      shouldBlockScroll
      triggerType="listbox"
      isOpen={isNotificationOpen}
      onOpenChange={(open) => setIsNotificationOpen(open)}
      
    >
      <Badge
        content={notifications.length > 0 && notifications.length}
        className="bg-red-500 text-white"
      >
        <PopoverTrigger>
          <Button
            isIconOnly
            variant="light"
            disableRipple
            disableAnimation
            className="border-1 rounded-full border-gray-400"
          >
            <BiBell className="text-xl" />
          </Button>
        </PopoverTrigger>
      </Badge>

      <PopoverContent className="p-2">
        <ScrollShadow hideScrollBar className="w-48 max-h-52">
          {notifications.length ? (
            notifications.map((item) => (
              <div
                key={item.id}
                className="p-2 rounded-lg cursor-pointer hover:bg-default-100"
                onClick={()=>handleClick(item.id)}
              >
                <Link to={`${roleLink[item.group]}${item.related_id}`}>
                  <p className="text-[0.8rem] font-semibold">{item.message}</p>
                  <p className="text-default-400 text-[0.7rem]">
                    {formatTime(item.createdAt)}
                  </p>
                </Link>
              </div>
            ))
          ) : (
            <p className="p-3 text-sm text-center">No new notifications</p>
          )}
        </ScrollShadow>
      </PopoverContent>
    </Popover>
  );
};

export default Notification;
