import React, { useState, useEffect, useCallback, useMemo } from "react";
import { IoMdAdd } from "react-icons/io";
import Addfields from "../pages/Admin/Addfields";
import { useForm } from "react-hook-form";
import { useNavigate } from "react-router-dom";
import { ImBin } from "react-icons/im";
import {
  Dropdown,
  DropdownTrigger,
  DropdownMenu,
  DropdownItem,
} from "@nextui-org/react";
import {
  Button,
  Input,
  Select,
  SelectItem,
  Modal,
  ModalContent,
  ModalHeader,
  ModalBody,
  useDisclosure,
  Spinner,
  Pagination,
  Divider,
} from "@nextui-org/react";
import axios from "axios";
import { useSelector, useDispatch } from "react-redux";

import {
  getMake,
  getMode,
  getAllPartType,
  getAllSalesRep,
} from ".././store/slices/customerSlice";
import { toast } from "react-toastify";
import {
  getBodyStyle,
  getTransmission,
  getFuelType,
  getWarranty,
  getDisputeTitle,
  getSuppliertype,
  getCancelReason,
} from ".././store/slices/adminSlice";
const Administration = () => {

  const [img, setImg] = useState("");
  const { makes, model, part_type, reps } = useSelector(
    (state) => state.customer
  );
  const {
    body_style,
    transmission,
    fuel_type,
    warranty,
    disputeTitle,
    suppliertype,
    cancelReason,
  } = useSelector((state) => state.admin);

  const [statusFilter, setStatusFilter] = useState("CUSTOMER");
  const { status } = useSelector((state) => state.supplier);
  const [fuel, setFuel] = useState("");
  const { isOpen, onOpen, onClose } = useDisclosure();
  const {
    isOpen: addAssignee,
    onOpen: sumAssignee,
    onClose: closeAssignee,
  } = useDisclosure();
  const navigate = useNavigate();
  let token = localStorage.getItem("accessToken");
  const [data, setData] = useState([]);
  const [property, setProperty] = useState("");
  const [sortDescriptor, setSortDescriptor] = useState({
    column: "first_name",
    direction: "ascending",
  })
  const [fieldID, setFieldID] = useState();
  const [deleteField, setDeleteField] = useState();
  const [deleteName, setDeleteName] = useState("");
  const [query, setQuery] = useState(" ")
  const [unit, setUnit] = useState("")
  const dispatch = useDispatch();
  const {
    control,
    formState: { errors },
  } = useForm();
  const [firstName, setFirstName] = useState("")
  const [fieldName, setFieldName] = useState([])
  const [selectedKeys, setSelectedKeys] = React.useState(new Set(["Select"]));

  const selectedValue = React.useMemo(
    () => Array.from(selectedKeys).join(", ").replaceAll("_", " "),
    [selectedKeys]
  );

  const getModelByMakeId = (id) => {
    setFieldID(id);
    dispatch(getMode(id));
  };

  const addfield = (val) => {
    setProperty(val);
    sumAssignee();
  };

  const getDeleteId = (val, id, name, unit) => {
    setDeleteName(val);
    setDeleteField(id);
    setQuery(name);
    setUnit(unit)
    onOpen();
  };

  const deleteItem = async () => {
    let token = localStorage.getItem("accessToken");
    let header = {
      headers: {
        authorization: `Bearer ${token}`,
      },
    };
    try {
      let res = await axios.delete(
        `${process.env.REACT_APP_SERVER_URL}/admin/delete${deleteName}/${deleteField}`,
        header
      );

      if (deleteName === warranty) {
        dispatch(getWarranty());
      }
      else if (deleteName === "bodystyle") {
        dispatch(getBodyStyle());
      }
      else if (deleteName === "transmission") {
        dispatch(getTransmission());
      }
      else if (deleteName === "fueltype") {
        dispatch(getFuelType());
      }
      else if (deleteName === "cancelreason") {
        dispatch(getCancelReason());
      }
      else if (deleteName === "disputetitle") {
        dispatch(getDisputeTitle());
      }
      else if (deleteName === "suppliertype") {
        dispatch(getAllSalesRep());
      }
      else if (deleteName === "part") {
        dispatch(getAllPartType());
      }
      dispatch(getMake());
      dispatch(getMode(fieldID));
      toast.success("Deleted successfully ⭐");
      onClose();
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    dispatch(getMake());
    dispatch(getMode());
    dispatch(getAllPartType());
    dispatch(getAllSalesRep());
    dispatch(getBodyStyle());
    dispatch(getTransmission());
    dispatch(getFuelType());
    dispatch(getWarranty());
    dispatch(getDisputeTitle());
    dispatch(getSuppliertype());
    dispatch(getCancelReason());
  }, [dispatch]);


  return (
    <div className="px-4">
      <div className="mb-4 border-b">
        <h1 className="text-base sm:text-xl font-semibold mb-4 ">
          Admin Management Portal
        </h1>
      </div>
      <div>
        <div className="mb-2 mt-4 md:mb-0 block md:grid md:grid-cols-3 items-center gap-4">
          <div className="mb-2 md:mb-0">
            <p className="text-sm font-medium mb-2">Make</p>
            <Dropdown className="w-full yugjhbiyuh">
              <DropdownTrigger>
                <Button
                  variant="bordered"
                  className="capitalize w-full text-left justify-start"
                >
                  {makes.length > 0 && makes[0].name}
                </Button>
              </DropdownTrigger>

              <DropdownMenu
                aria-label="Single selection example gjjhjh"
                disallowEmptySelection
                selectionMode="single"
                variant="bordered"
                selectedKeys={selectedKeys}
                onSelectionChange={setSelectedKeys}
                className="w-full text-left h-[200px] overflow-y-scroll"
              >
                {makes.map((item) => (
                  item?.id !== 0 &&
                  <DropdownItem
                    className="w-full"
                    key={item.name}
                    value={item.id}
                    onClick={() => getModelByMakeId(item.id)}
                  >
                    <div className="flex justify-between ">
                      {item.name}
                      <ImBin
                        style={{
                          fontSize: "1rem",
                          marginLeft: "60px",
                          color: "gray",
                        }}
                        onClick={() => getDeleteId("make", item.id, item.name)}
                      />
                    </div>
                  </DropdownItem>
                ))}
                {makes.length === 0 && (
                  <DropdownItem key={" "} value={" "}>
                    No items
                  </DropdownItem>
                )}
              </DropdownMenu>
            </Dropdown>
            <button
              className="flex items-center gap-2 text-small"
              type="button"
              onClick={() => addfield("vhiclemake")}
            >
              <IoMdAdd />
              <p className="text-sm m-1">Add</p>
            </button>
          </div>

          <div className="mb-2 md:mb-0">
            <p className="text-sm font-medium mb-2">Model</p>
            <Dropdown className="w-full">
              <DropdownTrigger>
                <Button
                  variant="bordered"
                  className="capitalize w-full justify-start"
                >
                  {model.length > 0 && model[0].name}
                </Button>
              </DropdownTrigger>

              <DropdownMenu
                aria-label="Single selection example"
                variant="bordered"
                disallowEmptySelection
                selectionMode="single"
                selectedKeys={selectedKeys}
                onSelectionChange={setSelectedKeys}
                className="h-1/4 overflow-y-scroll"
              >
                {model.map((item) => (
                  item?.id !== 0 &&
                  <DropdownItem key={item.name} value={item.id}>
                    <div className="flex justify-between">
                      {item.name}
                      <ImBin
                        style={{
                          fontSize: "1rem",
                          marginLeft: "140px",
                          color: "gray",
                        }}
                        onClick={() => getDeleteId("model", item.id, item.name)}
                      />
                    </div>
                  </DropdownItem>
                ))}
                {model.length === 0 && (
                  <DropdownItem key={" "} value={" "}>
                    No items
                  </DropdownItem>
                )}
              </DropdownMenu>
            </Dropdown>
            <button
              className="flex items-center gap-2 text-small"
              type="button"
              onClick={() => addfield("vhiclemodel")}
            >
              <IoMdAdd />
              <p className="text-sm m-1">Add</p>
            </button>
          </div>

          <div className="mb-2 md:mb-0">
            <p className="text-sm font-medium mb-2">Part Type</p>
            <Dropdown className="w-full">
              <DropdownTrigger>
                <Button
                  variant="bordered"
                  className="capitalize w-full justify-start"
                >
                  {part_type.length > 0 && part_type[0].name}
                </Button>
              </DropdownTrigger>

              <DropdownMenu
                aria-label="Single selection example"
                variant="bordered"
                disallowEmptySelection
                selectionMode="single"
                selectedKeys={selectedKeys}
                onSelectionChange={setSelectedKeys}
                className="w-full text-left h-[200px] overflow-y-scroll"
              >
                {part_type.map((item) => (
                  item?.id !== 0 &&
                  <DropdownItem key={item.name} value={item.id}>
                    <div className="flex justify-between">
                      {item.name}
                      <ImBin
                        style={{ fontSize: "1rem", color: "gray" }}
                        onClick={() => getDeleteId("part", item.id, item.name)}
                      />
                    </div>
                  </DropdownItem>
                ))}
                {part_type.length === 0 && (
                  <DropdownItem key={" "} value={" "}>
                    No items
                  </DropdownItem>
                )}
              </DropdownMenu>
            </Dropdown>
            <button
              className="flex items-center gap-2 text-small"
              type="button"
              onClick={() => addfield("parts")}
            >
              <IoMdAdd />
              <p className="text-sm m-1">Add</p>
            </button>
          </div>

          <div className="mb-2 md:mb-0">
            <p className="text-sm font-medium mb-2">Body Style</p>
            <Dropdown className="w-full">
              <DropdownTrigger>
                <Button
                  variant="bordered"
                  className="capitalize w-full justify-start"
                >
                  {body_style.length > 0 && body_style[0].name}
                </Button>
              </DropdownTrigger>

              <DropdownMenu
                aria-label="Single selection example"
                variant="bordered"
                disallowEmptySelection
                selectionMode="single"
                selectedKeys={selectedKeys}
                onSelectionChange={setSelectedKeys}
                className="w-full text-left h-1/4 overflow-y-scroll"
              >
                {body_style.map((item) => (
                  item?.id !== 0 &&
                  <DropdownItem key={item.name} value={item.id}>
                    <div className="flex justify-between">
                      {item.name}
                      <ImBin
                        style={{
                          fontSize: "1rem",
                          marginLeft: "160px",
                          color: "gray",
                        }}
                        onClick={() =>
                          getDeleteId("bodystyle", item.id, item.name)
                        }
                      />
                    </div>
                  </DropdownItem>
                ))}
                {body_style.length === 0 && (
                  <SelectItem key={" "} value={" "}>
                    No items
                  </SelectItem>
                )}
              </DropdownMenu>
            </Dropdown>
            <button
              className="flex items-center gap-2 text-small"
              type="button"
              onClick={() => addfield("bodystyle")}
            >
              <IoMdAdd />
              <p className="text-sm m-1">Add</p>
            </button>
          </div>

          <div className="mb-2 md:mb-0">
            <p className="text-sm font-medium mb-2">Fuel Type</p>
            <Dropdown className="w-full">
              <DropdownTrigger>
                <Button
                  variant="bordered"
                  className="capitalize w-full justify-start"
                >
                  {fuel_type.length > 0 && fuel_type[0].name}
                </Button>
              </DropdownTrigger>

              <DropdownMenu
                aria-label="Single selection example"
                variant="bordered"
                disallowEmptySelection
                selectionMode="single"
                selectedKeys={selectedKeys}
                onSelectionChange={setSelectedKeys}
                className="w-full text-left h-1/4 overflow-y-scroll"
              >
                {fuel_type?.map((item) => (
                  item?.id !== 0 &&
                  <DropdownItem key={item.name} value={item.id}>
                    <div className="flex justify-between w-full">
                      {item.name}
                      <ImBin
                        style={{
                          fontSize: "1rem",
                          marginLeft: "160px",
                          color: "gray",
                        }}
                        onClick={() =>
                          getDeleteId("fueltype", item.id, item.name)
                        }
                      />
                    </div>
                  </DropdownItem>
                ))}
                {body_style.length === 0 && (
                  <SelectItem key={" "} value={" "}>
                    No items
                  </SelectItem>
                )}
              </DropdownMenu>
            </Dropdown>

            <button
              className="flex items-center gap-2 text-small"
              type="button"
              onClick={() => addfield("fueltype")}
            >
              <IoMdAdd />
              <p className="text-sm m-1">Add</p>
            </button>
          </div>

          <div className="mb-2 md:mb-0">
            <p className="text-sm font-medium mb-2">Transmission</p>
            <Dropdown className="w-full">
              <DropdownTrigger>
                <Button
                  variant="bordered"
                  className="capitalize w-full  justify-start"
                >
                  {transmission.length > 0 && transmission[0].name}
                </Button>
              </DropdownTrigger>

              <DropdownMenu
                aria-label="Single selection example"
                variant="bordered"
                disallowEmptySelection
                selectionMode="single"
                selectedKeys={selectedKeys}
                onSelectionChange={setSelectedKeys}
                className="w-full text-left h-1/4 overflow-y-scroll"
              >
                {transmission?.map((item) => (
                  item?.id !== 0 &&
                  (<DropdownItem key={item.id} value={item.id}>
                    <div className="flex justify-between">
                      {item.name}
                      <ImBin
                        style={{
                          fontSize: "1rem",
                          marginLeft: "160px",
                          color: "gray",
                        }}
                        onClick={() => getDeleteId("transmission", item.id, item.name)}
                      />
                    </div>
                  </DropdownItem>
                  )

                ))}
                {transmission.length === 0 && (
                  <DropdownItem key={" "} value={" "}>
                    No items
                  </DropdownItem>
                )}
              </DropdownMenu>
            </Dropdown>
            <button
              className="flex items-center gap-2 text-small"
              type="button"
              onClick={() => addfield("transmission")}
            >
              <IoMdAdd />
              <p className="text-sm m-1">Add</p>
            </button>
          </div>

          <div className="mb-2  md:mb-0 ">
            <p className="text-sm font-medium mb-2">Warranty</p>
            <Dropdown className="w-full">
              <DropdownTrigger className="float-left text-left">
                <Button
                  variant="bordered"
                  className="capitalize w-full  justify-start"
                >
                  {warranty.length > 0 && warranty[0].duration}{" "}
                  {warranty.length > 0 && warranty[0].unit}
                </Button>
              </DropdownTrigger>

              <DropdownMenu
                aria-label="Single selection example"
                variant="bordered"
                disallowEmptySelection
                selectionMode="single"
                selectedKeys={selectedKeys}
                onSelectionChange={setSelectedKeys}
                className="w-full float-left h-1/4 overflow-y-scroll"
              >
                {warranty.map((item) => (
                  item?.id !== 0 &&
                  <DropdownItem
                    key={item.name}
                    value={item.id}
                    className="w-full"
                  >
                    <div className="flex justify-between">
                      {item.duration} {item.unit}
                      <ImBin
                        style={{
                          fontSize: "1rem",
                          marginLeft: "160px",
                          color: "gray",
                        }}
                        onClick={() =>
                          getDeleteId("warranty", item.id, item.duration, item.unit)
                        }
                      />
                    </div>
                  </DropdownItem>
                ))}
                {warranty.length === 0 && (
                  <DropdownItem key={" "} value={" "}>
                    No items
                  </DropdownItem>
                )}
              </DropdownMenu>
            </Dropdown>
            <button
              className="flex items-center gap-2 text-small"
              type="button"
              onClick={() => addfield("warranty")}
            >
              <IoMdAdd />
              <p className="text-sm m-1">Add</p>
            </button>
          </div>

          <div className="mb-2 md:mb-0">
            <p className="text-sm font-medium mb-2">Supplier Type</p>
            <Dropdown className="w-full">
              <DropdownTrigger>
                <Button
                  variant="bordered"
                  className="capitalize w-full  justify-start"
                >
                  {suppliertype.length > 0 && suppliertype[0].name}
                </Button>
              </DropdownTrigger>

              <DropdownMenu
                aria-label="Single selection example"
                variant="bordered"
                disallowEmptySelection
                selectionMode="single"
                selectedKeys={selectedKeys}
                onSelectionChange={setSelectedKeys}
                className="w-full text-left h-1/4 overflow-y-scroll"
              >
                {suppliertype.map((item) => (
                  item?.id !== 0 &&
                  <DropdownItem key={item.name} value={item.id}>
                    <div className="flex justify-between relative">
                      {item.name}
                      <ImBin
                        style={{
                          fontSize: "1rem",
                          marginLeft: "120px",
                          color: "gray",
                        }}
                        onClick={() =>
                          getDeleteId("suppliertype", item.id, item.name)
                        }
                      />
                    </div>
                  </DropdownItem>
                ))}
                {suppliertype.length === 0 && (
                  <DropdownItem key={" "} value={" "}>
                    No items
                  </DropdownItem>
                )}
              </DropdownMenu>
            </Dropdown>
            <button
              className="flex items-center gap-2 text-small"
              type="button"
              onClick={() => addfield("suppliertype")}
            >
              <IoMdAdd />
              <p className="text-sm m-1">Add</p>
            </button>
          </div>

          <div className="mb-2 md:mb-0">
            <p className="text-sm font-medium mb-2">Dispute Reasons</p>
            <Dropdown className="w-full">
              <DropdownTrigger>
                <Button
                  variant="bordered"
                  className="capitalize w-full  justify-start"
                >
                  {disputeTitle.length > 0 && disputeTitle[0].name}
                </Button>
              </DropdownTrigger>

              <DropdownMenu
                aria-label="Single selection example"
                variant="bordered"
                disallowEmptySelection
                selectionMode="single"
                selectedKeys={selectedKeys}
                onSelectionChange={setSelectedKeys}
                className="w-full text-left h-1/4 overflow-y-scroll"
              >
                {disputeTitle.map((item) => (
                  item?.id !== 0 && item?.id !== -1 &&
                  <DropdownItem key={item.name} value={item.id}>
                    <div className="flex justify-between">
                      {item.name}
                      <ImBin
                        style={{
                          fontSize: "1rem",
                          marginLeft: "120px",
                          color: "gray",
                        }}
                        className="bg-white"
                        onClick={() =>
                          getDeleteId("disputetitle", item.id, item.name)
                        }
                      />
                    </div>
                  </DropdownItem>
                ))}
                {disputeTitle.length === 0 && (
                  <DropdownItem key={" "} value={" "}>
                    No items
                  </DropdownItem>
                )}
              </DropdownMenu>
            </Dropdown>
            <button
              className="flex items-center gap-2 text-small"
              type="button"
              onClick={() => addfield("disputetitle")}
            >
              <IoMdAdd />
              <p className="text-sm m-1">Add</p>
            </button>
          </div>
          <div>
            <p className="text-sm font-medium mb-2">Cancel Reasons</p>
            <Dropdown className="w-full">
              <DropdownTrigger>
                <Button
                  variant="bordered"
                  className="capitalize w-full  justify-start"
                >
                  {cancelReason.length > 0 && cancelReason[0].name}
                </Button>
              </DropdownTrigger>

              <DropdownMenu
                aria-label="Single selection example"
                variant="bordered"
                disallowEmptySelection
                selectionMode="single"
                selectedKeys={selectedKeys}
                onSelectionChange={setSelectedKeys}
                className="w-full text-left h-1/4 overflow-y-scroll"
              >
                {cancelReason?.map((item) => (
                  item?.id !== 0 &&
                  <DropdownItem key={item.name} value={item.id}>
                    <div className="flex justify-between">
                      {item.name}
                      <ImBin
                        style={{
                          fontSize: "1rem",
                          marginLeft: "140px",
                          color: "gray",
                        }}
                        className="bg-white"
                        onClick={() =>
                          getDeleteId("cancelreason", item.id, item.name)
                        }
                      />
                    </div>
                  </DropdownItem>
                ))}
                {cancelReason.length === 0 && (
                  <DropdownItem key={" "} value={" "}>
                    No items
                  </DropdownItem>
                )}
              </DropdownMenu>
            </Dropdown>
            <button
              className="flex items-center gap-2 text-small"
              type="button"
              onClick={() => addfield("cancelreason")}
            >
              <IoMdAdd />
              <p className="text-sm m-1">Add</p>
            </button>
          </div>
        </div>
      </div>

      {/* model for deleting the field */}

      <Modal size={"xs"} isOpen={isOpen} onClose={onClose} placement="center">
        <ModalContent>
          {(onClose) => (
            <>
              <ModalHeader className="flex flex-col gap-1 justify-center">
                {`${deleteName === "warranty" ? `Do you want to delete " ${query} ${unit}"` : `Do you want to delete "${query}"`}`}

              </ModalHeader>
              <ModalBody>
                <div className="grid grid-cols-2 gap-2 pb-3">
                  <Button
                    className="border-2 border-orange-500 text-orange-500 bg-transparent hover:bg-orange-500 hover:text-white hover:border-none"
                    onPress={onClose}
                  >
                    Cancel
                  </Button>
                  <Button
                    className="border-2 border-green-500 text-green-500 bg-transparent hover:bg-green-500 hover:text-white hover:border-none"
                    onClick={deleteItem}
                  >
                    Delete
                  </Button>
                </div>
              </ModalBody>
            </>
          )}
        </ModalContent>
      </Modal>

      {/* modal for add field */}
      <Addfields
        addAssignee={addAssignee}
        property={property}
        fieldID={fieldID}
        closeAssignee={closeAssignee}
      />
    </div>
  );
};

export default Administration;
