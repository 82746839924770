import React, { useEffect, useState } from "react";
import {
  Button,
  Badge,
  Popover,
  ScrollShadow,
  PopoverContent,
  PopoverTrigger,
} from "@nextui-org/react";
import { BiMessage } from "react-icons/bi";
import { io } from "socket.io-client";
import { Link } from "react-router-dom";
import { formatTime } from "../utils";
import { useSelector } from "react-redux";
 
const ChatNotification = () => {
  const accessToken = localStorage.getItem("accessToken");
  const { user } = useSelector((state) => state.auth);
  const role = localStorage.getItem("role");
  const [socket, setSocket] = useState(null);
  const [notifications, setNotifications] = useState([]);
 
  const handleClick = (id) => {
    socket.on("deleteChatNotificationSuccess", (message) => {
      const data = notifications.filter((item)=>{
        return item.id!==message.messageId;
      })
      setNotifications(data);
    });

    socket.on("deleteChatNotificationError", (message) => {
      console.log(message);
    });

    socket.on("deleteChatNotificationNotFound", (message) => {
      console.log(message);
    });

    socket.emit("delete chat notification", {
      "id": id,
    });
  }

  useEffect(()=>{
   if(user?.id){
    const newSocket = io(
      `${process.env.REACT_APP_SOCKET_SERVER_URL}/chats/notifications/${user?.id}`,
      {
        extraHeaders: {
          Authorization: `Bearer ${accessToken}`,
        },
      } 
    );
    setSocket(newSocket);

    // Listen for getting previous chats
    newSocket.on("previous chat notification", (message) => {
      setNotifications(message);
      // setPrevMessages(message);
    });

    // Listen for getting new message
    newSocket.on("chat notification", (message) => {
      console.log(message);
      setNotifications((prevNotifications) => [...prevNotifications, message]);
    });

    return () => {
      newSocket?.disconnect?.();
    }
   }

  },[accessToken, user?.id])

  return (
    <Popover shouldBlockScroll triggerType="listbox">
      <Badge
        content={notifications.length > 0 && notifications.length}
        className="bg-red-500 text-white"
      >
        <PopoverTrigger>
          <Button
            isIconOnly
            variant="light"
            disableRipple
            disableAnimation
            className="border-1 rounded-full border-gray-400"
          >
            <BiMessage className="text-xl" />
          </Button>
        </PopoverTrigger>
      </Badge>

      <PopoverContent className="p-2">
        <ScrollShadow hideScrollBar className="w-48 max-h-52">
          {notifications.length ? (
          notifications.map((item, index) => (
          <div
            key={index}
            className="p-2 rounded-lg cursor-pointer hover:bg-default-100"
            onClick={()=>handleClick(item.id)}
          >
            <Link to={`${role==="CUSTOMER"?`/updateStatus/${item.related_id}`:`/quotes/${item.related_id}`}`}>
              <p className="text-[0.8rem] font-semibold">{item.message}</p>
              <p className="text-default-400 text-[0.7rem]">
                {formatTime(item.updatedAt)}
              </p>
            </Link>
          </div>
        ))
      ) : (
          <p className="p-3 text-sm text-center">No new chat messages</p>
          )} 
        </ScrollShadow>
      </PopoverContent>
    </Popover>
  );
};

export default ChatNotification;
