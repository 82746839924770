import React, { useEffect, useState } from "react";
import { Button, Input, Select, SelectItem, Textarea, Table, TableHeader, TableColumn, TableBody, TableRow, TableCell, Chip, Modal, ModalContent, ModalHeader, ModalBody, ModalFooter, useDisclosure } from "@nextui-org/react";
import { getAllPartType, getMake, getMode } from "../../store/slices/customerSlice";
import { getTransmission,getFuelType,getBodyStyle } from "../../store/slices/adminSlice";
import { inputStyle, inputWrapperStyle } from "../../data/inputStyleData";
import { useParams, useNavigate, Link } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import UpdateLayout from "../../layouts/UpdateLayout";
import { styling } from "../../layouts/UpdateLayout";
import { handleTypeNumber, imageResizer } from "../../utils";
import { useForm } from "react-hook-form";
import { toast } from "react-toastify";
import axios from "axios";

const UpdateRequest = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { makes, model, part_type, isloading } = useSelector((state) => state.customer);
  const{transmission,fuel_type,body_style}=useSelector((state)=>state.admin);
  const {isOpen, onOpen, onClose} = useDisclosure();
  const [requests, setRequests] = useState([]);
  const [images, setImages] = useState([]);
  const [quotes, setQuotes] = useState([]);
  const [values, setValues] = useState();
  const [trans, setTrans] = useState('');
  const [fuel, setFuel] = useState('');
  const [bodyStyle, setBodyStyle] = useState(0);
  const [image, setImage] = useState([]);
  const [models, setModels] = useState();
  const [make, setMake] = useState();
  const { id } = useParams();
  const {
    watch,
    register,
    handleSubmit,
    reset,
  } = useForm();

  const handleMakeChange = (event) => {
    setMake(event.target.value);
  };

  const handleModelChange = (event) => {
    setModels(event.anchorKey);
  };

  const handlePartType = (event) => {
    setValues(event.anchorKey);
  };

  const handleTransmission = (event) => {
    setTrans(event.anchorKey);
  };
  
  const handleFuelType = (event) => {
    setFuel(event.anchorKey);
  };

  const formatDate = (val) => {
    let date = new Date(val);
    const day = String(date?.getDate()).padStart(2, '0');
    const month = String(date?.getMonth() + 1).padStart(2, '0');
    const year = date?.getFullYear();
  
    return date ? `${day}/${month}/${year}` : 'Invalid Date';
  };


  const getRequestById = async (id) => {
    let token = localStorage.getItem("accessToken");
    let headers = {
      headers: {
        authorization: `Bearer ${token}`,
      },
    };
    try {
      let res = await axios.get(
        `${process.env.REACT_APP_SERVER_URL}/requests/get/${id}`,
        headers
      );
      let data = res.data.details;
      setRequests(data.request);
      setImage(data.images);
      if(data.request?.RequestStatus?.status==="Accepted"){
        toast.info("You have accepted the quote for this request.")
      }
      if(data.request?.RequestStatus?.status==="Canceled"){
        toast.info("You have canceled this request.")
      }
    } catch (error) {
      let err = error.response.data.message;
      toast.error(err);
    }
  };

  const updateData = async (data) => {
  
    let all_data = {};
    if(data.custom_make && data.custom_model){
      all_data={
        ...data,
        custom_make: data.custom_make,
        custom_model: data.custom_model,
        custom_part_type: data.custom_part_type,
        images: images,
        fuel_type:Number(fuel),
        transmission:Number(trans),
        body_Style:Number(bodyStyle)
      }
      delete all_data.make;
      delete all_data.model;
    }else if(data.custom_model){
      all_data = {
        ...data,
        make:Number(make),
        custom_model: data.custom_model,
        part_type:Number(values),
        images: images,
        fuel_type:Number(fuel),
        transmission:Number(trans),
        body_Style:Number(bodyStyle)
      };
      delete all_data.custom_make;
      delete all_data.model;
    }
    else{
      all_data={
        ...data,
        make: Number(make),
        model: Number(models),
        part_type:Number(values),
        images: images,
        fuel_type:Number(fuel),
        transmission:Number(trans),
        body_Style:Number(bodyStyle)
      }
      delete all_data.custom_make;
      delete all_data.custom_model;
      
    }    
    
    if(data.custom_part_type){
      all_data={
       ...all_data,
       custom_part_type: data.custom_part_type,
      
      }
      delete all_data.part_type;
      delete all_data.parts;

     }
     else{
      all_data={
         ...all_data,
         part_type:Number(values),
       }
       delete all_data.custom_part_type;
     } 
    
    
    try {
      let token = localStorage.getItem("accessToken");
      let header = {
        headers: {
          "Content-Type": "multipart/form-data",
          authorization: `Bearer ${token}`,
        },
      };
      let res = await axios.patch(
        `${process.env.REACT_APP_SERVER_URL}/requests/update/${id}`,
        all_data,
        header
      );
      toast.success(`${res.data?.message} 👌`);
      getRequestById(id);
      navigate('/requests')
    } catch (error) {
      let err = error.response.data.message;
      toast.error(err);
    }
  };

  const uploadImage = (event) => {
    let image_file = event.target.files[0];
    const maxSizeInBytes = 5 * 1024 * 1024;
    imageResizer(image_file, setImages);
  };

  const cancelRequest = () => {
    handleCancel();
    onClose();
  }

  const handleCancel = async () =>{
    try {
      const token = localStorage.getItem("accessToken");
      let header = {
        headers: {
            "Authorization":`Bearer ${token}`,
        }
      };
      let res = await axios.patch(`${process.env.REACT_APP_SERVER_URL}/requests/cancel/${id}`,"", header);
      toast.success(`${res.data?.message} 👍`)
      setTimeout(()=>{
        navigate("/requests");
      },750)
    } catch (error) {
      let err = error.response?.data?.message;
      toast.error(err);
    }
  }

  const getAllRequestsQuote = async (id) => {
      try {
        let token = localStorage.getItem("accessToken");
        let headers = {
          headers: {
            authorization: `Bearer ${token}`,
          },
        };
        let res = await axios.get(`${process.env.REACT_APP_SERVER_URL}/requests/${id}/quote/list`, headers);
        let data = res.data?.details;
        setQuotes(data);
      } catch (error) {
        let err = error.response?.data?.message;
        toast.info(err);
      }
  }

  useEffect(() => {
    getRequestById(id);
    dispatch(getMake());
    dispatch(getAllPartType());
    dispatch(getTransmission());
    dispatch(getFuelType());
    dispatch(getBodyStyle());
    getAllRequestsQuote(id);
  }, [dispatch, id]);


  useEffect(() => {
    if (requests) {
      dispatch(getMode(requests?.make))
      reset({
        year: requests.year,
        custom_part_type:requests.custom_part_type,
        part_type:requests?.Part?.name,
        custom_make:requests.custom_make,
        custom_model:requests.custom_model,
        fuel_type:requests.fuelType?.name,
        transmission:requests.transmissionModel?.name,
        notes: requests.notes,
        quantity: requests.quantity,
        rego: requests.rego,
        body_Style: requests.bodyStyle?.name,
        interchange_number: requests.interchange_number
      });
      setBodyStyle(Number(requests.bodyStyle?.id))
      setMake(String(requests?.VehicleMake?.id));
      setModels(String(requests?.VehicleModel?.id));
      setValues(String(requests?.Part?.id));
      setTrans(String(requests?.transmissionModel?.id));
      setFuel(String(requests?.fuelType?.id));

    }
  }, [requests, image, isloading, dispatch, reset, quotes]);

  return (
    <div>
      <UpdateLayout
        heading={"Request details"}
        title={"Update Request"}
        images={image}
        status={requests?.RequestStatus?.status}
      > 
        <form onSubmit={handleSubmit(updateData)}>
          <div className="block sm:grid sm:grid-cols-2 gap-4 my-5">
            {requests.VehicleMake?(
              <Select
              labelPlacement={"outside"}
              placeholder="select make"
              variant="bordered"
              className="w-full outline-none mb-2 sm:mb-0"
              selectedKeys={[make]} 
              onChange={handleMakeChange}
              label="Make"
            >
              {makes.map((item) => (
                 item?.id !== 0 && 
                <SelectItem
                  key={item.id}
                  value={item.id}
                  onClick={() => dispatch(getMode(item.id))}
                >
                  {item.name}
                </SelectItem>
              ))}
            </Select>):(<Input
              name="custom"
              type="text"
              label="Custom Make"
              variant="bordered"
              labelPlacement={"outside"}
              placeholder="Enter custom make"
              description={""}
              className=" mb-2 sm:mb-0"
              {...register("custom_make", { required: false })}
            /> ) }
            
            {
              requests.VehicleModel?(<Select
                labelPlacement={"outside"}
                placeholder="select model"
                variant="bordered"
                className="w-full outline-none mb-2 sm:mb-0"
                selectedKeys={[models]}
                onSelectionChange={handleModelChange}
                label="Model"
              >
                {model.map((item) => (
                   item?.id !== 0 && 
                  <SelectItem key={item.id} value={item.id}>
                    {item.name}
                  </SelectItem>
                ))}
              </Select>):(<Input
              name="custom"
              type="text"
              label="Custom Model"
              variant="bordered"
              labelPlacement={"outside"}
              placeholder="Enter custom make"
              description={""}
              className=" mb-2 sm:mb-0"
              {...register("custom_model", { required: false })}
            /> ) 
            }

            {requests.Part?(<Select
              labelPlacement={"outside"}
              variant="bordered"
              className="w-full mb-2 sm:mb-0"
              label="Part type"
              selectedKeys={[values]}
              onSelectionChange={handlePartType}
              placeholder="select part type"
            >
              {part_type.map((item) => (
                 item?.id !== 0 && 
                <SelectItem key={item.id} value={item.id}>
                  {item.name}  
                </SelectItem>
              ))}              
            </Select>):(
              <Input
              name="custom"
              type="text"
              label="Custom Part Type"
              variant="bordered"
              labelPlacement={"outside"}
              placeholder="Enter custom part type"
              description={""}
              className=" mb-2 sm:mb-0"
              onKeyDown={handleTypeNumber}
              {...register("custom_part_type", { required: false })}
            />
            )}

            <Select
              labelPlacement={"outside"}
              placeholder="Transmission"
              selectedKeys={[trans]}
              variant="bordered"
              className="w-full outline-none"
              label="Transmission"
              onSelectionChange={handleTransmission}
            >
              {transmission.map((item)=>(
                item.id !== 0 &&  <SelectItem key={item.id} value={item.id}>{item.name}</SelectItem>
              ))}
            </Select>
            <Select
              labelPlacement={"outside"}
              placeholder="Fuel Type"
              selectedKeys={[fuel]}
              variant="bordered"
              className="w-full outline-none"
              label="Fuel Type"
              onSelectionChange={handleFuelType}
            >
             {fuel_type.map((item)=>(
               item?.id !== 0 && 
              <SelectItem key={item.id}>{item.name}</SelectItem>
             ))}
            </Select>
            <Input
              name="year"
              type="text"
              label="Year"
              variant="bordered"
              labelPlacement={"outside"}
              placeholder="year"
              description={""}
              onWheel={(e) => e.target.blur()}
              className=" mb-2 sm:mb-0"
              onKeyDown={handleTypeNumber}
              {...register("year", { required: false })}
            />
            <Input
              name="quantity"
              type="number"
              label="Quantity"
              variant="bordered"
              labelPlacement={"outside"}
              placeholder="Quantity"
              description={""}
              onWheel={(e) => e.target.blur()}
              className=" mb-2 sm:mb-0"
              onKeyDown={handleTypeNumber}
              {...register("quantity", { required: false,valueAsNumber:true })}
            />
            
            <Input
              type="text"
              label="Rego"
              variant="bordered"
              className="mb-2 sm:mb-0"
              labelPlacement={"outside"}
              placeholder="Enter rego"
              description={""}
              {...register("rego", { required: false })}
              maxLength={10}
            />
            <Select
              labelPlacement={"outside"}
              placeholder="Body style"
              selectedKeys={[String(bodyStyle)]}
              variant="bordered"
              className="w-full outline-none"
              label="Body Style"
              {...register("body_Style", { required: false })}
              onSelectionChange={(event)=>(event.anchorKey)}
            >
             {body_style.map((item)=>(
               item?.id !== 0 && 
              <SelectItem key={item.id} onClick={()=>setBodyStyle(item.id)}>{item.name}</SelectItem>
             ))}
            </Select>
            <div className="">
              <Input
                type="file"
                label="Upload images ( Max 5 Images )"
                classNames={{
                  input: inputStyle,
                  inputWrapper: inputWrapperStyle,
                }}
                className=" mb-2 sm:mb-0"
                multiple
                accept="image/*"
                labelPlacement={"outside"}
                placeholder="Choose images"
                description={""}
                {...register("images", { required: false })}
                onChange={(e) => uploadImage(e)}
              />
            </div>
            <Textarea
              key={"bordered"}
              variant={"bordered"}
              label="Notes"
              labelPlacement="outside"
              placeholder="Any message"
              className="col-span-2 "
              {...register("notes", { required: false })}
            />
            {requests?.RequestStatus?.status==="New" || requests?.RequestStatus?.status==="In progress"?
            <>
              <Button type="submit" className=" bg-lightBlue font-semibold text-white py-1 px-5 mt-6" >
                Update
              </Button>
              <Button className=" bg-purpleBtn font-semibold text-white py-1 px-5 mt-6" onClick={requests?.RequestStatus?.status==="Canceled"?()=>{toast.error("Request has been already canceled.")}:()=>onOpen()}>{requests?.RequestStatus?.status==="Canceled"? "Canceled":"Cancel"}</Button>
            </>
            : ""
            }
          </div>
        </form>

        {requests?.RequestStatus?.status==="Accepted" && requests.Order!==null && <Link to={`/orders/${requests?.Order?.id}`}><Button className=" bg-purpleBtn font-semibold text-white py-1 px-5 mt-2">Check Order</Button></Link>}
        
      </UpdateLayout>


      <div className="px-0 sm:px-10 mt-5 sm:mt-10">
        <p className="text-base font-semibold mb-5">List of all quotes</p>
        <Table aria-label="Example static collection table">
          <TableHeader>
            <TableColumn>SHIP DATE</TableColumn>
            <TableColumn>WARRANTY DAYS</TableColumn>
            <TableColumn>TOTAL VALUE</TableColumn>
            <TableColumn>QUOTE_LINK</TableColumn>
          </TableHeader>
          <TableBody> 
            {quotes?.map((item, index)=>
              <TableRow key={index+1} className="!text-xs sm:!text-sm">
                <TableCell>{formatDate(item?.shipping_date)}</TableCell>
                <TableCell>{item?.warrantyDuration?.duration} {item?.warrantyDuration?.unit}</TableCell>
                <TableCell>{item?.total_price}</TableCell>
                <TableCell><Link to={`/updateStatus/${item.id}`}>
                <Button className="bg-purpleBtn font-semibold text-white py-1 px-5">Check Quote</Button></Link></TableCell>
              </TableRow>
            )}
            {quotes.length>0 ? "" : <TableRow>
                <TableCell key="1"></TableCell>
                <TableCell key="2">No Quotes found</TableCell>
                <TableCell key="3"></TableCell>
                <TableCell key="3"></TableCell>
              </TableRow>}
          </TableBody>
        </Table>
      </div>
      
      {/* modal for cancel request */}
      <Modal 
        size={"xs"} 
        isOpen={isOpen} 
        onClose={onClose}
        placement="center" 
      >
        <ModalContent>
          {(onClose) => (
            <>
              <ModalHeader className="flex flex-col gap-1 justify-center">Are you sure?</ModalHeader>
              <ModalBody>
                <div className="grid grid-cols-2 gap-2 pb-3">
                  <Button className="border-2 border-orange-500 text-orange-500 bg-transparent hover:bg-orange-500 hover:text-white hover:border-none" onPress={onClose}>No</Button>
                  <Button className="border-2 border-green-500 text-green-500 bg-transparent hover:bg-green-500 hover:text-white hover:border-none" onClick={cancelRequest}>Yes</Button>
                </div>
              </ModalBody>
            </>
          )}
        </ModalContent>
      </Modal>
    </div>
  );
  
};

export default UpdateRequest;
