import React, { useState, useEffect } from "react";
import { inputStyle, inputWrapperStyle } from "../../data/inputStyleData";
import { Input, Select, SelectItem, Textarea } from "@nextui-org/react";
import { handleTypeNumber, imageResizer } from "../../utils";
import { useSelector, useDispatch } from "react-redux";
import QuoteLayout from "../../layouts/QuoteLayout";
import { useNavigate } from "react-router-dom";
import { Controller, useFieldArray, useForm } from "react-hook-form";
import { IoMdAdd } from "react-icons/io";
import { GoDash } from "react-icons/go";
import { toast } from "react-toastify";
import CreatableSelect from "react-select/creatable";
import { getFormData } from "../../store/slices/formSlice";
import {
  getMake,
  getMode,
  getAllPartType,
} from "../../store/slices/customerSlice";
import "./Request.css";
import {
  getBodyStyle,
  getFuelType,
  getTransmission,
} from "../../store/slices/adminSlice";
import axios from "axios";

const RequestGen = () => {
  const {
    control,
    register,
    handleSubmit,
    watch,
    formState: { errors },
    reset,
  } = useForm({
    defaultValues: {
      parts: [{ part_id: 0, quantity: 1 }],
    },
  });
  const { fields, append, remove } = useFieldArray({
    control,
    name: "parts",
  });

  const [customFields, setCustomFields] = useState([]);
  const [customMake, setCustomMake] = useState([]);
  const [show, setShow] = useState(false);
  const [quantity, setQuantity] = useState(1);
  const [showModel, setShowModel] = useState(false);
  const [removeVisible, setRemoveVisible] = useState(true);
  const { makes, model, part_type } = useSelector((state) => state.customer);
  const { body_style, fuel_type, transmission } = useSelector(
    (state) => state.admin
  );

  const user = useSelector((state) => state.customer);
  const formData = useSelector((state) => state.form.formData);
  const [images, setImages] = useState([]);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [selectedId, setSelectedId] = useState(null);
  const [customName, setCustomName] = useState("");
  const [combinedOption, setCombinedOption] = useState([]);
  let token = localStorage.getItem("accessToken");
  const [options, setOptions] = useState([]);

  let usersDetails = JSON.parse(localStorage.getItem("user_details"));

  const getModelByMakeId = (id) => {
    dispatch(getMode(id));
  };

  const addCustomField = () => {
    append({ part_id: "", quantity: 1 });
    setRemoveVisible(true);
    setCustomFields([watch("parts"), { part_type: "", quantity: 1 }]);
  };

  const addCustomMake = () => {
    setShow(!show);
    setCustomMake([...customMake, { custom_make: "", custom_model: "" }]);
  };

  const addCustomModel = () => {
    setShowModel(true);
  }; 

  const dataUrlToBlog = (dataUrl) => {
    // Decode the Base64 string (ignoring the metadata part)
    const byteString = atob(dataUrl?.split(",")[1]);

    // Extract the MIME type from the metadata
    const mimeString = dataUrl.split(",")[0].split(":")[1].split(";")[0];

    // Create an ArrayBuffer with the same length as the byte string
    const ab = new ArrayBuffer(byteString.length);

    // Create a Uint8Array view of the ArrayBuffer
    const ia = new Uint8Array(ab);

    // Populate the Uint8Array with the decoded byte string
    for (let i = 0; i < byteString.length; i++) {
      ia[i] = byteString.charCodeAt(i);
    }

    // Create a Blob from the ArrayBuffer and specify the MIME type
    return new Blob([ab], { type: mimeString });
  };

  const convertToBase64 = (file) => {
    return new Promise((resolve, reject) => {
      const fileReader = new FileReader();
      fileReader.onload = (e) => {
        // console.log('File content:', e.target.result); // Base64 encoded string
        resolve(e.target.result);
      };
      fileReader.readAsDataURL(file);

      fileReader.onerror = (error) => reject(error);
    });
  };

  const getForm = () => {
    const fileURL = localStorage.getItem("fileURL");
    const imageBlob = dataUrlToBlog(fileURL);
    const formData = new FormData();
    formData.append("image", imageBlob); // add image field to formData
  };  
  
  useEffect(() => {
    const initialOptions = part_type
      .filter((item) => item?.id !== 0)
      .map((item) => ({ value: item.id, key: item.id, label: item.name }));
    setOptions(initialOptions);
  }, [part_type]);

  const handleCreate = (inputValue) => {
    setCustomName(inputValue.label);
    setCombinedOption((prev)=>[...prev, inputValue.label])
  };  

  const handleChange = (e,index) => {
    if (e.key) {
      setSelectedId(e.key);
    } else {
      setCustomName(e);
      setCombinedOption((prev)=>[...prev, e])
    }
  };


  const onSubmit = async (data) => {
    let parts = data.parts;

    let total_data = {};
    if (data.make && data.model) {
      total_data = {
        ...data,
        make: data.make,
        model: data.model,
      };

      delete total_data.custom_make;
      delete total_data.custom_model;
    } else if (data.make && data.custom_model) {
      total_data = {
        ...data,
        make: data.make,
        custom_model: data.custom_model,
      };
      delete total_data.custom_make;
      delete total_data.model;
    } else if (data.custom_make && data.custom_model) {
      total_data = {
        ...data,
        custom_make: data.custom_make,
        custom_model: data.custom_model,
      };
      delete total_data.make;
      delete total_data.model;
    }

    if (!token) {
      const { images } = data;
      const file = images[0];
      const fileURL = await convertToBase64(file);
      let files = [];
      for (let i = 0; i < images.length; i++) {
        const fileURL = await convertToBase64(images[i]);
        files.push(fileURL);
      }
      total_data = {
        ...total_data,
        images: files,
      };
      localStorage.setItem("fileURL", files);
      dispatch(getFormData(total_data));
    } else {
      total_data = {
        ...total_data,
        images: images,
      };
    } 
  
     parts = parts.map((e) => {
      if (!isNaN(e.part_id)) {
        return e;
      } else{
        return{
          custom_part_type:e.part_id,
          quantity:e.quantity
        }
      }
    });



    total_data = {
      ...total_data,
      parts: parts,
    };
    try {
      if (token) {
        let res = await axios.post(
          `${process.env.REACT_APP_SERVER_URL_V2}/requests/create`,
          total_data,
          {
            headers: {
              "Content-Type": "multipart/form-data",
              authorization: `Bearer ${token}`,
            },
          }
        );
        toast.success("Created successfully ⭐");
        setTimeout(() => {
          navigate("/requests");
          localStorage.removeItem("total_data");
        }, 1000);
      } else {
        setTimeout(() => {
          navigate("/signup/CUSTOMER");
        }, 1000);
      }
    } catch (error) {
      let err = error.response?.data?.message;
      toast.error(err);
    }
  };

  const data = JSON.parse(localStorage.getItem("total_data"));

  const uploadImage = async (event) => {
    let image_file = event.target.files[0];
    imageResizer(image_file, setImages);
  };

  useEffect(() => {
    dispatch(getMake());
    dispatch(getAllPartType());
    dispatch(getBodyStyle());
    dispatch(getFuelType());
    dispatch(getTransmission());
  }, [dispatch]);

  return (
    <div>
      <div>
        <h1 className="text-2xl text-center font-semibold">Create Request:</h1>
        <form onSubmit={handleSubmit(onSubmit)} encType="multipart/form-data">
          <QuoteLayout heading={" "}>
            {!show ? (
              <div className="mb-2 md:mb-0 relative">
                <p className="text-red-500 absolute left-10 -top-1">*</p>
                <Select
                  labelPlacement={"outside"}
                  placeholder="Make"
                  variant="bordered"
                  className="w-full outline-none mb-2"
                  label="MAKE"
                  value={`${data?.make}`}
                  {...register("make", { required: true })}
                >
                  {makes.map(
                    (item) =>
                      item?.id !== 0 && (
                        <SelectItem
                          key={item.id}
                          value={Number(item.id)}
                          onClick={() => getModelByMakeId(item.id)}
                        >
                          {item.name}
                        </SelectItem>
                      )
                  )}
                </Select>
                {errors.make && (
                  <p className="text-xs text-red-500 pl-2 pt-2">
                    Select the makes
                  </p>
                )}
                <button
                  className="flex items-center gap-2 text-small"
                  type="button"
                  onClick={addCustomMake}
                >
                  <IoMdAdd />{" "}
                  <p className="text-md sm:text-xs">
                    If your Vehicle Make isn't listed, click here to list
                  </p>
                </button>
              </div>
            ) : (
              <div className="relative">
                <p className="text-red-500 absolute left-24 -top-1">*</p>
                <Input
                  type="text"
                  label="Custom Make"
                  variant="bordered"
                  className="mb-2 "
                  labelPlacement={"outside"}
                  placeholder="Custom Make"
                  {...register("custom_make", { required: false })}
                />
                <button
                  className="flex items-center gap-2 text-small"
                  type="button"
                  onClick={addCustomMake}
                >
                  <IoMdAdd />{" "}
                  <p className="text-md sm:text-xs">Add listed Vehicle Make</p>
                </button>
              </div>
            )}

            {!show && !showModel ? (
              <div className="mb-2 md:mb-0 relative">
                <p className="text-red-500 absolute left-12 -top-1">*</p>
                <Select
                  labelPlacement={"outside"}
                  variant="bordered"
                  className="w-full mb-2"
                  label="MODEL"
                  placeholder="Model"
                  {...register("model", { required: true })}
                >
                  {model.map(
                    (item) =>
                      item?.id !== 0 && (
                        <SelectItem key={item.id}>{item.name}</SelectItem>
                      )
                  )}
                </Select>
                {errors.model && (
                  <p className="text-xs text-red-500 pl-2 pt-2">
                    Select the model
                  </p>
                )}
                <button
                  className="flex items-center gap-2 text-small"
                  type="button"
                  onClick={addCustomModel}
                >
                  <IoMdAdd />{" "}
                  <p className="text-md sm:text-xs">Add your model </p>
                </button>
              </div>
            ) : (
              <div className="relative">
                <p className="text-red-500 absolute left-24 -top-1">*</p>
                <Input
                type="text"
                label="Custom Model"
                variant="bordered"
                className="mb-6 w-full"
                labelPlacement={"outside"}
                placeholder="Custom Model"
                {...register("custom_model", { required: false })}
              />
              </div>
            )}

            <div className="mb-2 sm:mb-0 relative">
              <p className="text-red-500 absolute left-10 -top-1">*</p>
              <Input
                type="number"
                label="YEAR"
                variant="bordered"
                className="mb-2"
                labelPlacement={"outside"}
                placeholder="Enter year"
                description={""}
                onWheel={(e) => e.target.blur()}
                onKeyDown={handleTypeNumber}
                {...register("year", { required: true, valueAsNumber: true })}
              />
              {errors.year && (
                <p className="text-xs text-red-500 pl-2"> Enter the year </p>
              )}
            </div>

            <div className="mb-2 sm:mb-0 relative">
            <p className="text-red-500 absolute left-10 -top-1">*</p>
              <Input
                type="text"
                label="REGO"
                variant="bordered"
                className="mb-2  "
                labelPlacement={"outside"}
                placeholder="Enter rego"
                description={""}
                {...register("rego", { required: true })}
                maxLength={10}
              />
            </div>
            <div className="col-span-2">
              {fields?.map((field, index) => (
                <div
                  key={field.id}
                  className="mb-2 md:mb-0 block md:grid md:grid-cols-2 items-center gap-4"
                >
                  
                  <div className="mb-2 md:mb-0 relative">
                  <p className="text-red-500 absolute left-32 -top-1">*</p>
                    <h2>Select Part Type</h2>
                    <Controller
                      name={`parts.${index}.part_id`}
                      control={control}
                      defaultValue={field.part_id || ""}
                      rules={{
                        required: "Select the part type",
                        valueAsNumber: true,
                      }}
                      render={({ field: controllerField }) => {
                        return (
                          <CreatableSelect
                            {...controllerField}
                            label="Select Part Type"
                            labelPlacement={"outside"}
                            options={options}
                            className="container"
                            value={
                              options.find((option) => {
                                if(!isNaN(controllerField?.value)){
                                  return option.value === controllerField.value;
                                }else{
                                  return combinedOption.includes(controllerField.value)
                                }
                              })
                            }
                            onChange={(selectedOption) => {
                              controllerField.onChange(selectedOption.value);
                              handleChange(selectedOption);
                            }}
                            onCreate={handleCreate}
                          />
                        );
                      }}
                    />
                    {errors?.parts?.[index]?.part_id && (
                      <p className="text-xs text-red-500 pl-2">
                        {errors.parts[index].part_id.message}
                      </p>
                    )}
                  </div>
                  <div className="mb-2 md:mb-0 relative">
                    <p className="text-red-500 absolute left-20">*</p>
                    <Controller
                      name={`parts.${index}.quantity`}
                      control={control}
                      defaultValue={field.quantity || ""}
                      rules={{ required: false, valueAsNumber: true }}
                      render={({ field }) => (
                        <Input
                          {...field}
                          type="number"
                          label="QUANTITY"
                          variant="bordered"
                          className="mt-1"
                          labelPlacement={"outside"}
                          value={field.value === 1 ? 0 : field.value}
                          onWheel={(e) => e.target.blur()}
                          placeholder="0"
                          onKeyDown={(e) => {
                            console.log(field)
                            if (
                              !/[0-9]/.test(e.key) &&
                              e.key !== "Backspace" &&
                              e.key !== "Delete"
                            ) {
                              e.preventDefault();
                            }
                          }}
                        />
                      )}
                    />
                    {removeVisible && (
                      <button
                        className="flex items-center gap-2 text-small float-right"
                        type="button"
                        onClick={() => remove(index)}
                      >
                        <GoDash />
                        <p className="text-md sm:text-xs">Remove</p>
                      </button>
                    )}
                  </div>
                </div>
              ))}
              <button
                type="button"
                className="flex items-center gap-2 text-small"
                onClick={addCustomField}
              >
                <IoMdAdd /> <p className="sm:text-xs">Add another part</p>
              </button>
            </div>

            <div className="mb-2 md:mb-0 relative">
              <p className="text-red-500 absolute left-28 -top-1">*</p>
              <Select
                labelPlacement={"outside"}
                placeholder="Transmission"
                variant="bordered"
                className="w-full outline-none"
                label="TRANSMISSION"
                {...register("transmission", { required: true })}
              >
                {transmission?.map(
                  (item) =>
                    item?.id !== 0 && (
                      <SelectItem key={item.id} value={item.id}>
                        {item.name}
                      </SelectItem>
                    )
                )}
              </Select>
              {errors.transmission && (
                <p className="text-xs text-red-500 pl-2 pt-2">
                  Select the Transmission
                </p>
              )}
            </div>

            <div className="mb-2 md:mb-0">
              <Select
                labelPlacement={"outside"}
                placeholder="Fuel Type"
                variant="bordered"
                className="w-full outline-none"
                label="FUEL TYPE"
                {...register("fuel_type")}
              >
                {fuel_type?.map(
                  (item) =>
                    item?.id !== 0 && (
                      <SelectItem key={item.id} value={item.name}>
                        {item.name}
                      </SelectItem>
                    )
                )}
              </Select>
              {errors.fuel_type && (
                <p className="text-xs text-red-500 pl-2 pt-2">
                  Select the Fuel Type
                </p>
              )}
            </div>
            <div className="mb-2 sm:mb-0 relative">
              <p className="text-red-500 absolute left-24 -top-1">*</p>
              <Select
                labelPlacement={"outside"}
                placeholder="Body Style"
                variant="bordered"
                className="mb-2"
                label="BODY STYLE"
                {...register("body_Style", { required: true })}
              >
                {body_style?.map(
                  (item) =>
                    item?.id !== 0 && (
                      <SelectItem key={item.id} value={item.id}>
                        {item.name}
                      </SelectItem>
                    )
                )}
                {body_style.length === 0 && (
                  <SelectItem key={" "} value={" "}>
                    No items
                  </SelectItem>
                )}
              </Select>
              {errors.body_style && (
                <p className="text-xs text-red-500 pl-2 pt-2">
                  Select the Body style
                </p>
              )}
            </div>

            <div className="mb-2 sm:mb-0">
              <Input
                label="UPLOAD IMAGES ( Max 5 Images )"
                className="mb-2"
                classNames={{
                  input: inputStyle,
                  inputWrapper: inputWrapperStyle,
                }}
                multiple
                type="file"
                {...register("images")}
                accept="image/*"
                labelPlacement={"outside"}
                placeholder="Choose images"
                description={""}
                {...register("images", { required: false })}
                onChange={(e) => uploadImage(e)}
              />
            </div>
            <div className="col-span-2">
              <Textarea
                key={"bordered"}
                variant={"bordered"}
                label="NOTES"
                labelPlacement="outside"
                placeholder="Any message"
                {...register("notes", { required: false })}
              />
            </div>
          </QuoteLayout>
          <button
            className="h-9 bg-lightBlue text-white rounded-lg py-1 px-5 mt-6"
            onClick={getForm}
          >
            Submit
          </button>
        </form>
      </div>
    </div>
  );
};

export default RequestGen;
