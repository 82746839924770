const columns = [
    { name: "Status", uid: "status", sortable: true },
    { name: "Customer", uid: "customer", sortable: true },
    { name: "Company", uid: "company", sortable: true },
    { name: "Car", uid: "car", sortable: true },
    { name: "Part", uid: "part", sortable: true },
    { name: "Created", uid: "duration", sortable: true },
];

const users =[
    {name: "Profile", uid: "profile", sortable: true},
    {name: "Role", uid: "role", sortable: true},
    {name: "fullName", uid: "fullName", sortable: true},
    {name: "car", uid: "car", sortable: true},
    {name: "email", uid: "email", sortable: true},
    {name: "part", uid: "part", sortable: true},
    {name:"phone",uid:"phone",sortable:true},
    {name:"created",uid:"created",sortable:true}
]

const statusOptions = [
    { name: "all", uid: "all" },
    { name: "Initiated", uid: "initiated" },
    { name: "Processing", uid: "processing" },
    { name: "Shipped", uid: "shipped" },
    { name: "In Transit", uid: "in Transit" },
    { name: "Completed", uid: "completed" },
    { name: "Delivered", uid: "delivered" },
    { name: "Dispute", uid: "dispute" },
    { name: "Cancelled", uid: "cancelled" },
];
  
export { columns, statusOptions,users };