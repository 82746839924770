import React, { useEffect, useRef, useState } from "react";
import {
  Avatar,
  Button,
  Chip,
  Textarea,
  Popover,
  PopoverContent,
  PopoverTrigger,
} from "@nextui-org/react";
import { ImAttachment } from "react-icons/im";
import { io } from "socket.io-client";
import { displayTime, formatFileSize } from "../utils";
import { FaFile } from "react-icons/fa6";
import { BiSolidPaperPlane } from "react-icons/bi";
import { IoMdChatbubbles, IoMdClose } from "react-icons/io";

const ChatBox = ({ quoteId }) => {
  const accessToken = localStorage.getItem("accessToken");
  const role = localStorage.getItem("role");

  const [socket, setSocket] = useState(null);
  const [isChatOpen, setIsChatOpen] = useState(false);
  const [prevMessages, setPrevMessages] = useState([]);
  const [newMessage, setNewMessage] = useState("");
  const [fileToUpload, setFileToUpload] = useState("");
  const [uploadedFile, setUploadedFile] = useState(null);
  const scrollRef = useRef(null);

  useEffect(() => {
    // initializing Socket.io
    const newSocket = io(
      `${process.env.REACT_APP_SOCKET_SERVER_URL}/chats/${quoteId}`,
      {
        extraHeaders: {
          Authorization: `Bearer ${accessToken}`,
        },
      }
    );
    // console.log(newSocket)
    setSocket(newSocket);

    // Listen for getting previous chats
    newSocket.on("previous chat", (message) => {
      setPrevMessages(message);
    });

    // Listen for getting new message
    newSocket.on("chat message", (message) => {
      console.log(message)
      setPrevMessages((prevMessages) => [...prevMessages, message])
    });

    // Listen for getting new message with attachment
    newSocket.on("file upload", (message) => {
      setPrevMessages((prevMessages) => [...prevMessages, message]);
    });
 
    return () => {
      newSocket.disconnect();
    };
  }, [accessToken, quoteId]);

  useEffect(() => {
    // Scroll to the latest message on initial render
    scrollRef.current?.scrollIntoView({ behavior: "smooth" });
  }, [prevMessages]);

  // Sending a new message
  const handleSendMessage = (e) => {
    e.preventDefault();
    
    // console.log(e)
    if (newMessage.trim() !== ""){  
      socket.emit("chat message", {
        notes: newMessage,  
      })
      setNewMessage("");
    }

    if (fileToUpload !== "") {
      socket.emit("file upload", {
        attachments: fileToUpload,
      });
      setFileToUpload("");
      setUploadedFile(null);
    }
  };

  // on uploading attachment file
  const handleUpload = (e) => {
    const file = e.target.files[0];

    if (file) {
      const maxSizeInBytes = 5 * 1024 * 1024; // 5 MB
      if (file.size > maxSizeInBytes) {
        alert(
          "File size exceeds the maximum allowed size (5 MB). Please choose a smaller file."
        );
        // Clear the file input
        e.target.value = null;
      } else {
        // Read the file as a data URL
        const reader = new FileReader();

        reader.onload = (e) => {
          setUploadedFile((prev) => {
            return {
              ...prev,
              fileName: file.name,
              fileSize: file.size,
              filePath: e.target.result,
            };
          });
        };

        reader.onloadend = () => {
          setFileToUpload(reader.result.split(",")[1]);
        };

        reader.readAsDataURL(file);
      }
    }
  };

  // sending the message on pressing the Enter key
  const handleKeyDown = (e) => {
    if (e.key === "Enter" && !e.shiftKey) {
      handleSendMessage(e);
    } else if (e.key === "Enter" && e.shiftKey) {
      setNewMessage(newMessage + "\n");
    }
  };

  return (
    <div className="fixed bottom-20 sm:bottom-6 right-4 z-[999]">
      <Popover
        isOpen={isChatOpen}
        shouldBlockScroll
        shouldFlip={false}
        onOpenChange={(open) => setIsChatOpen(open)}
      >
        <PopoverTrigger>
          <Button
            isIconOnly
            size="lg"
            className="bg-orange-100 text-orange-400 drop-shadow-lg hover:scale-110"
          >
            {isChatOpen ? (
              <IoMdClose className="text-4xl" />
            ) : (
              <IoMdChatbubbles className="text-3xl" />
            )}
          </Button>
        </PopoverTrigger>

        <PopoverContent>
          <div className="w-[17rem] sm:w-96 py-2 rounded-lg max-w-lg ml-auto overflow-hidden">
            <div>
              <h4 className="mb-4 font-semibold">Direct messages</h4>
            </div>

            <div className="px-5 py-6 grid bg-[#f2f3f5] max-h-[400px] overflow-y-auto">
              <div>
                {prevMessages?.map((item, index) => (
                  <div
                    ref={scrollRef}
                    className={`flex gap-3 ${
                      item?.user_type === role ? "justify-end" : ""
                    } mb-5`}
                    key={index}
                  >
                   
                    <div
                      className={`px-2.5 py-2 rounded-lg ${
                        item?.user_type === role
                          ? "rounded-tr-none bg-white"
                          : "rounded-tl-none bg-gray-200"
                      }`}
                    >
                      {item?.attachments_url && (
                        <div>
                          <a
                            href={item?.attachments_url}
                            target="_blank"
                            rel="noreferrer"
                          >
                            <img src={item?.attachments_url} alt="file" />
                          </a>
                        </div>
                      )}
                      {item?.notes && <p> {item?.notes}</p>}
                      <p className="text-right text-sm text-slate-500/90">
                        <sub>{displayTime(item?.createdAt)}</sub>
                      </p>
                    </div>

                    <Avatar
                      className={`rounded-full ${
                        item?.user_type === role ? "hidden" : "order-[-1]"
                      }`}
                    />
                  </div>
                ))}
              </div>
            </div>

            <form onSubmit={handleSendMessage}>
              <div className="bg-[#f2f3f5] mt-2 py-3 px-2 outline-none">
                {uploadedFile ? (
                  <div className="mb-3">
                    <Chip
                      radius="md"
                      className="px-3 py-7 bg-slate-50"
                      classNames={{
                        content: "p-0",
                        closeButton: "ml-5",
                      }}
                      onClose={() => setUploadedFile(null)}
                    >
                      <div className="flex gap-3">
                        <div className="w-12 h-12 rounded-md overflow-hidden">
                          <Avatar
                            src={uploadedFile?.filePath}
                            alt="file"
                            radius="none"
                            showFallback
                            fallback={
                              <FaFile className="text-2xl text-slate-700" />
                            }
                            className="w-[100%] h-[100%] object-cover"
                          />
                        </div>
                        <div>
                          <p className="text-xs font-semibold">
                            {uploadedFile?.fileName}
                          </p>
                          <span className="text-xs">
                            {formatFileSize(uploadedFile?.fileSize)}
                          </span>
                        </div>
                      </div>
                    </Chip>
                  </div>
                ) : null}

                <div className="grid grid-cols-[auto_1fr_auto] items-center">
                  <div>
                    <input
                      type="file"
                      id="file-upload"
                      onChange={handleUpload}
                      hidden
                    />
                    <label
                      htmlFor="file-upload"
                      className="block cursor-pointer"
                    >
                      <Button
                        isIconOnly
                        variant="light"
                        className="pointer-events-none"
                      >
                        <ImAttachment />
                      </Button>
                    </label>
                  </div>

                  <div>
                    <Textarea
                      minRows={1}
                      maxRows={3}
                      placeholder="Type a message"
                      value={newMessage}
                      onChange={(e) =>
                        e.nativeEvent.inputType !== "insertLineBreak" &&
                        setNewMessage(e.target.value)
                        // console.log(e.target.value)
                      }
                      onKeyDown={handleKeyDown}
                      classNames={{
                        label: "hidden",
                      }}
                    />
                  </div>

                  <Button
                    type="submit"
                    isIconOnly
                    variant="light"
                    isDisabled={!newMessage && !uploadedFile}
                  >
                    <BiSolidPaperPlane className="text-lg" />
                  </Button>
                </div>
              </div>
            </form>
          </div>
        </PopoverContent>
      </Popover>
    </div>
  );
};

export default ChatBox;
