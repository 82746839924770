import PageRoutes from "./routes/PageRoutes";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

const App = () => {
  return (
    <>
      <ToastContainer
        position="top-right"
        autoClose={2500}
        closeOnClick
        draggable
        pauseOnHover
        theme="light"
      />
      <PageRoutes />
    </>
  );
};

export default App;
