import React from 'react'
import { Button } from '@nextui-org/react'
import { SlArrowRight } from "react-icons/sl";
import { AiOutlineCheck } from "react-icons/ai";
import { useNavigate } from 'react-router-dom';

export const Stripe = () => {
    const navigate=useNavigate();
  return (
    <div className='  border border-gray-100 shadow-md rounded-xl w-full p-4 flex flex-col items-center justify-center gap-2'>
    <div className='border-[8px] border-green-600  rounded-full p-2 my-2'>
    <AiOutlineCheck style={{color:'green', fontSize:"80px"}}/>
    </div>
    <h1 className='text-3xl text-green-600 font-bold'>Success</h1>
    <p className='font-semibold text-gray-500'>Your connection established successfully on Stripe</p>


   <div className='flex mt-6'>
   <Button
   
   onClick={()=>navigate('/orders')}
   className='text-xl text-white bg-green-600 font-semibold'>Continue  <SlArrowRight style={{color: 'white' , marginLeft:"5px"}}/></Button>
   </div>

</div> 
  )
}
