import Resizer from "react-image-file-resizer";

// capitalizing string
export const capitalize = (str) => {
  return str.charAt(0).toUpperCase() + str.slice(1);
};

// evaluating time
export const formatTime = (timestamp) => {
  let date1 = new Date();
  let date2 = new Date(timestamp);
  let differenceMs = Math.abs(date1 - date2);
  const days = Math.floor(differenceMs / (1000 * 60 * 60 * 24));
  const hours = Math.floor(
    (differenceMs % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60)
  );
  const minutes = Math.floor((differenceMs % (1000 * 60 * 60)) / (1000 * 60));

  let diff = Math.floor(date1.getTime() - date2.getTime());
  let day = Math.floor(diff/(1000 * 60 * 60 * 24));
  let months = Math.floor(day/31);
  let years = Math.floor(months/12);
  // Check if remaining time is less than a day
  if (days === 0 && hours === 0) {
    if (minutes === 0) {
      return `Just now`;
    }
    return `${minutes} ${minutes > 1 ? "mins ago" : "min ago"}`;
  } else if (days === 0) {
    return `${hours} ${hours > 1 ? "hrs ago" : "hr ago"}`;
  } else  if(months === 0){
    return `${days} ${days > 1 ? "days ago" : "day ago"}`;
  } else if(years === 0){
    return `${months} ${months >1 ? "months age" : "month ago"}`
  }else{
    return `${years} ${years >1 ? "years age" : "year ago"}`
  }
};

// displaying date
export const displayDate = (timestamp) => {
  return timestamp?.split("T")[0];
};

// displaying time
export const displayTime = (timestamp) => {
  const formattedTimestamp = new Date(timestamp);

  return formattedTimestamp.toLocaleTimeString("en-AU", {
    hour: "2-digit",
    minute: "2-digit",
  });
};

// checking date changes
export const hasDateChanged = (timestamp) => {
  const currentDate = new Date();
  const previousDate = new Date(timestamp);

  return previousDate.getTime() !== currentDate.getTime();
};

// evaluating file size
export const formatFileSize = (fileSize) => {
  if (fileSize < 1024) {
    return fileSize + " Bytes";
  } else if (fileSize < 1024 * 1024) {
    return (fileSize / 1024).toFixed(2) + " KB";
  } else {
    return (fileSize / (1024 * 1024)).toFixed(2) + " MB";
  }
};

// prevent typing "e", "-", ".", "+" in input type number
export const handleTypeNumber = e => ( e.keyCode === 69 || e.keyCode === 190 || e.keyCode === 187 || e.keyCode === 189) && e.preventDefault()


export const imageResizer = (file, setImages) => {
  try {
    Resizer.imageFileResizer(
      file,
      300,
      300,
      "JPEG",
      100,
      0,
      (uri) => {    
        setImages((prev)=>[...prev, uri])       
      },
      "file",
      200,
      200
    );
  } catch (err) {
    console.log(err);
  }
}