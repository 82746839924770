import React, { useEffect, useState } from "react";
import { useParams, useNavigate, Link, useLocation } from "react-router-dom";
import { Chip, User, Button } from "@nextui-org/react";
import ImageSlider from "../../components/ImageSlider";
import { useSelector } from "react-redux";
import { toast } from "react-toastify";
import axios from "axios";

const RequestDetails = () => {
  const quote_id = localStorage.getItem("quote_id");
  const { user } = useSelector((state) => state.auth);
  const [request, setRequest] = useState([]);
  const [images, setImages] = useState([]);
  const [create, setCreate] = useState(false);
  const [suppliers, setSuppliers] = useState([]);
  const navigate = useNavigate();
  const location = useLocation();
  const containsGenerateQuote = location.pathname.includes("generateQuote");
  const { id } = useParams();

  const handleCreateQuote = () => {
    let data = {
      id,
      customer_id: request?.request?.customer_id,
      username: request?.request?.User,
      quantity: request?.request?.quantity,
    };
    localStorage.setItem("customer_request", JSON.stringify(data));
    navigate(`/request/generateQuote/${id}`);
  };

  const formatDate = (val) => {
    let date = new Date(val);
    const day = String(date?.getDate()).padStart(2, '0');
    const month = String(date?.getMonth() + 1).padStart(2, '0');
    const year = date?.getFullYear();
  
    return date ? `${day}/${month}/${year}` : 'Invalid Date';
  };

  const handleClick = () => {
    localStorage.removeItem("quote_id");
  };

  useEffect(() => {
    (async () => {
      try {
        let token = localStorage.getItem("accessToken");
        let header = {
          headers: {
            "Content-Type": "multipart/form-data",
            authorization: `Bearer ${token}`,
          },
        };
        let res = await axios.get(
          `${process.env.REACT_APP_SERVER_URL}/requests/get/${id}`,
          header
        );

        let data = res.data?.details;
        let suppliers = data?.supplierIds;
        setRequest(res.data?.details);
        setImages(data.images);
        setSuppliers(data.supplierIds);
        if (suppliers.length > 0) {
          let val = suppliers.find((item) => {
            if (user.id === item) {
              return true;
            }
            return false;
          });
          if (val !== undefined) {
            setCreate(true);
          }
        }
      } catch (error) {
        let err = error.response?.data?.message;
        toast.error(err);
      }
    })();
  }, [user?.id, id]);

  return (
    <div className="grid md:grid-cols-2 gap-3 box-border w-full">
      {/* ------ slider for showing image ------ */}
      {images?.length > 0 ? (
        <ImageSlider images={images} />
      ) : (
        <div className="flex justify-center pt-9 font-semibold">
          No image found
        </div>
      )}
      {/* ------- product details ------ */}
      <div className="pt-2">
        <div className="flex justify-between">
          <h2 className="font-bold text-lg">Details about request</h2>
          <Chip
            color={
              request?.request?.RequestStatus?.status === "New"
                ? "primary"
                : "warning"
            }
            variant="dot"
            className={` ${
              request?.request?.RequestStatus?.status === "New"
                ? "text-blue-400 border-blue-500"
                : "text-orange-400 border-orange-500"
            }`}
          >
            {request?.request?.RequestStatus?.status}
          </Chip>
        </div>
        {request?.request?.User?.capricorn_number && "(Capricorn Member)"}
        <div className="grid gap-y-8 [&>div>div>p:first-child]:text-[0.9rem] [&>div>div>p:last-child]:font-semibold">
          <div className="grid grid-cols-4 mt-8 gap-2 [&>div>p:last-child]:text-[0.75rem] sm:[&>div>p:last-child]:text-[0.875rem]">
            <div>
              <p>Industry</p>
              <p>{request?.request?.User?.Industry}</p>
            </div>

            <div>
              <p>City</p>
              <p>{request?.request?.User?.suburb}</p>
            </div>
            <div>
              <p>State</p>
              <p>{request?.request?.User?.state}</p>
            </div>
            <div>
              <p>Postcode</p>
              <p>{request?.request?.User?.postcode}</p>
            </div>
          </div>
          <div className="grid grid-cols-2 gap-2 [&>div>p:last-child]:text-[0.75rem] sm:[&>div>p:last-child]:text-[0.875rem]">
            <div>
              <p>Created On</p>
              <p>{formatDate(request?.request?.date_requested)}</p>
            </div>
          </div>

          <div className="grid grid-cols-3 gap-2 [&>div>p:last-child]:text-[0.75rem] sm:[&>div>p:last-child]:text-[0.875rem]">
            <div>
              <p>Make Name</p>
              <p>
                {request?.request?.VehicleMake
                  ? request?.request?.VehicleMake?.name
                  : request?.request?.custom_make}
              </p>
            </div>
            <div>
              <p>Model Name</p>
              <p>
                {request?.request?.VehicleModel
                  ? request.request?.VehicleModel?.name
                  : request?.request?.custom_model}
              </p>
            </div>
            <div>
              <p>Year</p>
              <p>{request?.request?.year}</p>
            </div>
            <div>
              <p>Transmission</p>
              <p>{request?.request?.transmissionModel?.name}</p>
            </div>
            <div>
              <p>Rego</p>
              <p>{request?.request?.rego}</p>
            </div>
            <div>
              <p>Fuel Type</p>
              <p>{request?.request?.fuelType?.name}</p>
            </div>
          </div>
          <div className="grid grid-cols-2 gap-2 [&>div>p:last-child]:text-[0.75rem] sm:[&>div>p:last-child]:text-[0.875rem]">
            <div>
              <p>Part Name</p>
              <p>
                {request?.request?.Part ? request?.request?.Part.name : request?.request?.custom_part_type}
              </p>
            </div>
            <div>
              <p>Quantity</p>
              <p>{request?.request?.quantity}</p>
            </div>
            <div>
              <p>Body Style</p>
              <p>{request?.request?.bodyStyle?.name}</p>
            </div>
          </div>
          <div>
            <div>
              <p>Description</p>
              <p className="text-[0.75rem] sm:text-[0.875rem]">
                {request?.request?.notes}
              </p>
            </div>
          </div>
        </div>

        {create && !containsGenerateQuote ? (
          <div>
            <Button className="bg-lightBlue text-white py-2 px-5 font-bold text-sm sm:text-base mt-5 mobile-only:w-full">
              Already Quote exist
            </Button>
            <br />
            {quote_id && (
              <Link to={`/quotes/${quote_id}`}>
                <Button
                  className="bg-purpleBtn text-white py-2 px-5 font-bold text-sm sm:text-base mt-5"
                  onClick={handleClick}
                >
                  Go to quote
                </Button>
              </Link>
            )}
          </div>
        ) : (
          !containsGenerateQuote && (
            <Button
              className="bg-lightBlue text-white py-2 px-5 font-bold text-sm sm:text-base mt-5 mobile-only:w-full"
              onClick={handleCreateQuote}
            >
              Create Quote
            </Button>
          )
        )}
      </div>
    </div>
  );
};

export default RequestDetails;
