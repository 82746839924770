import React, { useEffect, useState } from "react";
import {
  Button,
  Input,
  Modal,
  ModalContent,
  ModalBody,
  SelectItem,
  Select,
} from "@nextui-org/react";
import {
  getMode,
  getAllPartType,
  getMake,
  getAllSalesRep,
} from "../../store/slices/customerSlice";
import {
  getBodyStyle,
  getTransmission,
  getWarranty,
  getFuelType,
  getSuppliertype,
  getDisputeTitle,
  getCancelReason,
} from "../../store/slices/adminSlice";
import { useDispatch } from "react-redux";
import axios from "axios";
import { toast } from "react-toastify";

const Addfields = ({
  addAssignee,
  closeAssignee,
  property,
  sales,
  fieldID,
}) => {
  const dispatch = useDispatch();
  const [value, setValue] = useState("");
  const [dayNo, setDayNo] = useState();
  const [unit, setUnit] = useState();
  const [id, setId] = useState();

  const addPartType = async () => {
    let data = {};
    if (property === "vhiclemodel") {
      data = { makeId: id, name: value };
    } else if (property === "warranty") {
      data = { duration: dayNo, unit: unit };
    } else {
      data = { name: value };
    }
    try {
      let token = localStorage.getItem("accessToken");
      let header = {
        headers: {
          authorization: `Bearer ${token}`,
        },
      };
      let res = await axios.post(
        `${process.env.REACT_APP_SERVER_URL}/admin/add${property}`,
        data,
        header
      );

      if (property === "warranty") {
        dispatch(getWarranty());
      } else if (property === "bodystyle") {
        dispatch(getBodyStyle());
      } else if (property === "transmission") {
        dispatch(getTransmission());
      } else if (property === "fueltype") {
        dispatch(getFuelType());
      } else if (property === "suppliertype") {
        dispatch(getSuppliertype());
      } else if (property === "parts") {
        dispatch(getAllPartType());
      } else if (property === "disputetitle") {
        getDisputeTitle();
      } else if (property === "cancelreason") {
        getCancelReason();
      }
      dispatch(getMake());
      dispatch(getMode(id));
      toast.success("Created successfully ⭐");
      closeAssignee();
    } catch (error) {
      const err = error.response.data.message;
      toast.error(err);
    }
  };

  useEffect(() => {
    dispatch(getMode());
  }, [dispatch]);

  // const addSupplier = async (data) => {
  //   try {
  //     let token = localStorage.getItem("accessToken");
  //     let header = {
  //       headers: {
  //         authorization: `Bearer ${token}`,
  //       },
  //     };
  //     let res = await axios.post(
  //       `${process.env.REACT_APP_SERVER_URL}/api/v1/suppliersalesreps/create?${sales}`,
  //       data,
  //       header
  //     );
  //     dispatch(getAllSalesRep);
  //     toast.success("Created successfully ⭐");
  //     closeAssignee();
  //   } catch (error) {
  //     console.log(error);
  //   }
  // };

  const update = (event) => {
    setValue(event.target.value);
    if (property === "vhiclemodel") {
      setId(fieldID);
    } else if (property === "warranty") {
      setDayNo(event.target.value);
      console.log(event.target.value);
    }
  };

  const updateUnit = (event) => {
    setUnit(event.currentKey);
  };

  return (
    <Modal
      size={"xs"}
      isOpen={addAssignee}
      onClose={closeAssignee}
      placement="center"
    >
      <ModalContent>
        {(onClose) => (
          <>
            <ModalBody>
              {property !== "warranty" ? (
                <Input
                  type="text"
                  className="my-2"
                  label="Add here"
                  labelPlacement={"outside"}
                  placeholder="Enter "
                  description={""}
                  onChange={(event) => update(event)}
                />
              ) : (
                <div className="flex gap-2">
                  <Input
                    type="number"
                    className="my-2 w-1/3"
                    variant="bordered"
                    label="Add here"
                    labelPlacement={"outside"}
                    placeholder="Enter "
                    description={""}
                    onChange={(event) => update(event)}
                  />
                  <Select
                    labelPlacement={"outside"}
                    className="my-2"
                    label="Duration"
                    placeholder="Select duration"
                    onSelectionChange={(event) => updateUnit(event)}
                  >
                    <SelectItem key="days" value="days">
                      Days
                    </SelectItem>
                    <SelectItem key="weeks " value="weeks">
                      Weeks
                    </SelectItem>
                    <SelectItem key="months" value="months">
                      Months
                    </SelectItem>
                    <SelectItem key="years" value="years">
                      Year
                    </SelectItem>
                  </Select>
                </div>
              )}

              <div className="grid grid-cols-2 gap-2 pb-3">
                <Button
                  className="border-2 border-orange-500 text-orange-500 bg-transparent hover:bg-orange-500 hover:text-white hover:border-none"
                  onClick={() => closeAssignee()}
                >
                  Cancel
                </Button>
                <Button
                  className="border-2 border-green-500 text-green-500 bg-transparent hover:bg-green-500 hover:text-white hover:border-none"
                  onClick={() => addPartType()}
                >
                  Submit
                </Button>
              </div>
            </ModalBody>
          </>
        )}
      </ModalContent>
    </Modal>
  );
};

export default Addfields;
