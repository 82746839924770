import React, { useEffect, useState } from "react";
import {
  Button,
  Chip,
  Modal,
  ModalContent,
  ModalHeader,
  ModalBody,
  ModalFooter,
  useDisclosure,
  Textarea,
} from "@nextui-org/react";
import UpdateLayout from "../layouts/UpdateLayout";
import { useParams } from "react-router-dom";
import { toast } from "react-toastify";
import axios from "axios";

const DisputeMessage = () => {
  const { id } = useParams();
  const role = localStorage.getItem("role");
  const token = localStorage.getItem("accessToken");
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [disputeDetails, setDisputeDtails] = useState({});
  const [disputeRole, setDisputeRole] = useState("");
  const [message, setMessage] = useState("");
  const [multipleOffer, setMultipleOffer] = useState([]);


  const handleOpen = () => {
    onOpen();
  };

  const handleResponse = async () => {
    let data = {
      response: message,
    };
    try {
      let res = await axios.patch(
        `${process.env.REACT_APP_SERVER_URL}/disputes/${id}/response`,
        data,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      getDisputeById(id);
      onClose();
      toast.success("Response added successfully.");
    } catch (error) {
      let err = error.response?.data?.message;
      toast.error(err);
    }
  };

  const getDisputeById = async (id) => {
    try {
      const header = {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      };
      let res = await axios.get(
        `${process.env.REACT_APP_SERVER_URL}/disputes/${id}`,
        header
      );
      let dispute_role = res.data?.details?.disputes?.description;
      if (dispute_role.includes("SUPPLIER")) {
        setDisputeRole("SUPPLIER");
      } else if (dispute_role.includes("CUSTOMER")) {
        setDisputeRole("CUSTOMER");
      }
      setDisputeDtails(res.data?.details);
      setMultipleOffer(res.data?.details?.disputes?.Order?.Quote?.QuoteOptions)
    } catch (error) {
      let err = error.response?.data?.message;
      toast.error(err);
    }
  };

  useEffect(() => {
    getDisputeById(id);
  }, []);

  return (
    <div>
      <UpdateLayout
        heading={"Dispute order details"}
        title=""
        images={disputeDetails?.images}
        requestId={disputeDetails?.disputes?.Order?.request_id}
      >
        <div className="flex justify-between items-center mt-8 mb-5">
          <p className="font-semibold">Order details</p>
          <Chip
            color="danger"
            variant="dot"
            className="text-red-400 font-semibold border-red-400"
          >
            Dispute
          </Chip>
        </div>
        <div className="dispute-text mt-9">
          <div>
            <p>Order Id</p>
            <p className="text-[0.75rem]">{disputeDetails?.disputes?.id}</p>
          </div>
          <div>
            <p>Quantity</p>
            <p>{disputeDetails?.disputes?.Order?.Request?.quantity}</p>
          </div>
          <div>
            <p>Recieved Order Date</p>
            <p className="text-[0.75rem]">
              {disputeDetails?.disputes?.received_date?.split("T")[0]}
            </p>
          </div>
        </div>
        <div className="dispute-text">
          <div>
            <p>Make</p>
            <p>{disputeDetails?.disputes?.Order?.Request?.VehicleMake?.name}</p>
          </div>
          <div>
            <p>Model</p>
            <p>
              {disputeDetails?.disputes?.Order?.Request?.VehicleModel?.name}
            </p>
          </div>
          <div>
            <p>Part Type</p>
            <p>{disputeDetails?.disputes?.Order?.Request?.Part?.name}</p>
          </div>
        </div>
        <div className="dispute-text">
          <div>
            <p>Part Price</p>
            <p>{disputeDetails?.disputes?.Order?.Quote?.part_price}</p>
          </div>
          <div>
            <p>Frieght Price</p>
            <p>{disputeDetails?.disputes?.Order?.Quote?.freight_price}</p>
          </div>
          <div>
            <p>Total Price</p>
            <p>{disputeDetails?.disputes?.Order?.Quote?.total_price}</p>
          </div>
        </div>

        <div className="mt-2 text-center">
          <span className="text-sm sm:text-base font-semibold">Title: </span>
          <span className={`${disputeDetails?.disputes?.titlee?.name === "Credit Request" ? "bg-yellow-200 text-[0.8rem] px-2 py-1" : "text-[0.8rem]"}`}>
            {disputeDetails?.disputes?.titlee?.name}
          </span>
        </div>
        <div className="mt-2 text-center">
          <span className="text-sm sm:text-base font-semibold">
            Description:{" "}
          </span>
          <span className="text-[0.8rem]">
            {disputeDetails?.disputes?.description.split(":")[1]}
          </span>
        </div>

        {multipleOffer?.length > 0 && (<div>
          <p className="font-semibold">Additional Offers:</p>
          {multipleOffer.map((e) => (
            <div className="dispute-text">
              <div>
                <p>{e?.Part?.name ? "Part" : "Custom Part"}</p>
                <p>{e?.Part?.name ? e?.Part?.name : e?.custom_part_type}</p>
              </div>
              <div>
                <p>Part Price</p>
                <p>${e?.part_price}</p>

              </div>
              <div>
                <p>Freight Price</p>
                <p>${e?.freight_price}</p>
              </div>
              <div>
                <p>Total Price</p>
                <p>${e?.total}</p>
              </div>
            </div>
          ))}
        </div>)}

        {disputeRole &&
          role !== disputeRole &&
          !disputeDetails?.disputes?.response ? (
          <div className="flex justify-center mt-7">
            <Button
              className="bg-lightBlue font-semibold text-white px-7 mobile-only:w-full"
              onPress={() => handleOpen()}
            >
              Add your thought
            </Button>
          </div>
        ) : (
          ""
        )}

      </UpdateLayout>
      {disputeDetails?.disputes?.response ? (
        <div className="mx-0 sm:mx-10 my-5 ">
          <p className="font-semibold pb-2 border-b-1 border-gray-300">
            Response:
          </p>
          <div className="mt-3">
            <p className="text-xs sm:text-sm">
              {disputeDetails?.disputes?.response.split(":")[1]}
            </p>
          </div>
        </div>
      ) : (
        ""
      )}
      {/* modal for submiting the question and answer */}
      <Modal size={"md"} isOpen={isOpen} onClose={onClose} placement="center">
        <ModalContent>
          {(onClose) => (
            <>
              <ModalHeader className="flex flex-col gap-1">
                Submit Your Response On this
              </ModalHeader>
              <ModalBody>
                <Textarea
                  key={"bordered"}
                  variant={"bordered"}
                  label={"Response"}
                  labelPlacement="outside"
                  placeholder={"Write Here"}
                  className="col-span-12 md:col-span-6 mb-6 md:mb-0"
                  onChange={(e) => setMessage(e.target.value)}
                />
              </ModalBody>
              <ModalFooter>
                <Button className="bg-purpleBtn text-white" onPress={onClose}>
                  Close
                </Button>
                <Button
                  className="bg-lightBlue text-white"
                  onPress={handleResponse}
                >
                  Submit
                </Button>
              </ModalFooter>
            </>
          )}
        </ModalContent>
      </Modal>
    </div>
  );
};

export default DisputeMessage;
