import { createSlice } from "@reduxjs/toolkit";
import axios from "axios";

const initialState = {
  countries: [],
  states: [],
  cities: [],
};

export const countrySlice = createSlice({
  name: "country",
  initialState,
  reducers: {
    allCountries: (state, action) => {
      return {
        ...state,
        countries: action.payload,
      };
    },

    allStates: (state, action) => {
      return {
        ...state,
        states: action.payload,
      };
    },

    allCities: (state, action) => {
      return {
        ...state,
        cities: action.payload,
      };
    },
  },
});

export const { allCountries, allStates, allCities } = countrySlice.actions;
export default countrySlice.reducer;

// getting all the Countries
export const getCountries = () => {
  return async (dispatch) => {
    try {
      let response = await axios.get(
        "https://countriesnow.space/api/v0.1/countries"
      );
      let responseData = response?.data?.data;
      dispatch(allCountries(responseData));
    } catch (error) {
      console.log(error);
    }
  };
};

// getting all the States of Australia
export const getStates = () => {
  return async (dispatch) => {
    try {
      let response = await axios.post(
        "https://countriesnow.space/api/v0.1/countries/states",
        {
          country: "Australia",
        }
      );
      let responseData = response?.data?.data;
      dispatch(allStates(responseData?.states));
    } catch (error) {
      console.log(error);
    }
  };
};

// getting all the Cities of Australia
export const getCities = (data) => {
  return async (dispatch) => {
    try {
      let response = await axios.post(
        "https://countriesnow.space/api/v0.1/countries/state/cities",
        {
          country: "Australia",
          ...data,
        }
      );
      let responseData = response?.data?.data;
      dispatch(allCities(responseData));
    } catch (error) {
      console.log(error);
    }
  };
};
