import React from "react";
import { SlArrowRight } from "react-icons/sl";
import { MdOutlineCancel } from "react-icons/md";
import { useNavigate } from "react-router-dom";

export const Failed = () => {
  const navigate = useNavigate();
  return (
    <div className="  border border-gray-100 shadow-md rounded-xl w-full p-4 flex flex-col items-center justify-center gap-3 mt-4">
      <div className="border-[1px] border-dashed border-red-600  rounded-full p-3 my-2">
        <MdOutlineCancel style={{ color: "red", fontSize: "90px" }} />
      </div>
      <h1 className="text-3xl text-red-500 font-bold">Payment Failed</h1>
      <p className="font-semibold text-gray-500">Your transaction has failed</p>
      <div className="flex mt-4">
        <button
          className="text-lg text-red-500 font-semibold"
          onClick={() => navigate("/orders")}
        >
          Retry Payment{" "}
        </button>
        <SlArrowRight
          style={{ color: "red", marginTop: "8px", marginLeft: "10px" }}
        />
      </div>
    </div>
  );
};
