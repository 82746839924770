import React, { useState, useEffect, useCallback } from "react";
import {
  Button,
  Input,
  Select,
  SelectItem,
  Textarea,
  Modal,
  ModalContent,
  ModalHeader,
  ModalBody,
  useDisclosure,
  Chip,
} from "@nextui-org/react";
import { IoAddSharp } from "react-icons/io5";
import { inputStyle, inputWrapperStyle } from "../../data/inputStyleData";
import { useNavigate, useParams } from "react-router-dom";
import UpdateLayout from "../../layouts/UpdateLayout";
import { chipValue } from "../../data/styleData";
import ChatBox from "../../components/ChatBox";
import { imageResizer } from "../../utils";
import { useForm } from "react-hook-form";
import AcceptOrder from "./AcceptOrder";
import { toast } from "react-toastify";
import axios from "axios";
import AddAssignee from "./AddAssignee";
import {
  getAllSalesRep,
  getAllPartType,
} from "../../store/slices/customerSlice";
import { getWarranty } from "../../store/slices/adminSlice";
import { useDispatch, useSelector } from "react-redux";

const UpdateQuote = () => {
  const { id } = useParams();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { reps, part_type } = useSelector((state) => state.customer);
  const { warranty } = useSelector((state) => state.admin);
  const [quote, setQuote] = useState([]);
  const [images, setImages] = useState([]);
  const [request, setRequest] = useState([]);
  const [sales, setSales] = useState([]);

  const [warrantys, setWarrantyId] = useState("0");
  const { isOpen, onOpen, onClose } = useDisclosure();
  const {
    isOpen: addAssignee,
    onOpen: sumAssignee,
    onClose: closeAssignee,
  } = useDisclosure();
  const { register, handleSubmit, watch, reset } = useForm();
  const [imageUpload, setImageUpload] = useState(new Set([]));

  let token = localStorage.getItem("accessToken");
  let headers = {
    headers: {
      "Content-Type": "multipart/form-data",
      authorization: `Bearer ${token}`,
    },
  };

  const uploadImage = (event) => {
    let image_file = event.target.files[0];
    imageResizer(image_file, setImages);
  };

  const updateData = async (data) => {
    try {
      const total_price = Number(totalBill(request?.quantity));
      let total_data = {
        ...data,
        images: imageUpload,
        total_price,
      };

      let res = await axios.patch(
        `${process.env.REACT_APP_SERVER_URL_V2}/quotes/update/${id}`,
        total_data,
        headers
      );
      toast.success(res.data.message);
      getQuoteById(id);
    } catch (error) {
      let err = error.response.data.message;
      toast.error(err);
    }
  };

  const totalGST = (quantity) => {
    let count_tax = watch("part_price") * quantity * (10 / 100);
    let total_tax = Number(count_tax.toFixed(2));
    return total_tax.toFixed(1);
  };

  const totalBill = (quantity) => {
    let freight_price_tax = parseFloat(watch("freight_price")) * (10 / 100);
    let total_bill =
      Number(totalGST(quantity)) +
      watch("part_price") * quantity +
      watch("freight_price") +
      freight_price_tax +
      (watch("warranty_price") ? watch("warranty_price") : 0);
    return total_bill.toFixed(1);
  };

  const getQuoteById = useCallback(async () => {
    try {
      let token = localStorage.getItem("accessToken");
      let headers = {
        headers: {
          "Content-Type": "multipart/form-data",
          authorization: `Bearer ${token}`,
        },
      };
      let res = await axios.get(
        `${process.env.REACT_APP_SERVER_URL}/quotes/${id}`,
        headers
      );
      let data = res.data.details;
      setQuote(data?.quote);
      setRequest(data?.quote?.Request);
      setImages(data?.quote_images);
      if (data.quote?.status === 4) {
        toast.info("Your quote has been rejected.");
      } else if (data.quote?.status === 5) {
        toast.info("Your quote is discarded.");
      } else if (data.quote?.status === 6) {
        toast.info("You have canceled the quote.");
      } else if (data.quote?.status === 7) {
        toast.info("This quote has been dispute");
      }
    } catch (error) {
      let err = error.response.data.message;
      toast.error(err);
    }
  }, [id]);

  const cancelQuote = () => {
    handleCancel();
    onClose();
  };

  const handleCancel = async () => {
    try {
      let res = await axios.patch(
        `${process.env.REACT_APP_SERVER_URL}/quotes/cancel/${id}`,
        "",
        headers
      );
      toast.success("Canceled the quote successfully.");
      setTimeout(() => {
        navigate("/quotes");
      }, 750);
    } catch (error) {
      let err = error?.response?.data?.message;
      console.log(error);
      toast.error(err);
    }
  };

  const getCommission = async () => {
    try {
      let res = await axios.get(
        `${process.env.REACT_APP_SERVER_URL}/admin/commssionrate/${id}`,
        " ",
        headers
      );
      console.log(res.data);
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    getQuoteById(id);
    dispatch(getAllSalesRep());
    dispatch(getAllPartType());
    dispatch(getWarranty());
    getCommission();
  }, []);

  useEffect(() => {
    const resetInputVal = () => {
      if (Object.keys(quote).length > 0) {
        reset({
          part_price: quote?.part_price,
          shipping_date: quote?.shipping_date?.split("T")[0],
          shipping_address: quote?.shipping_address,
          freight_price: quote?.freight_price,
          warranty: quote?.warranty,
          warranty_option: quote?.warranty_option,
          interchange_number: quote?.interchange_number,
          warranty_price: quote?.warranty_price,
          notes: quote?.notes,
          sales_repId: quote?.sales_repId,
        });
      }
      setWarrantyId(String(quote?.warranty));
      setSales(String(quote?.sales_repId));
    };
    resetInputVal();
  }, [quote, reset]);
  return (
    <div>
      {quote?.status === 1 ||
      quote?.status === 2 ||
      quote?.status === 4 ||
      quote?.status === 5 ||
      quote?.status === 6 ||
      quote?.status === 7 ? (
        <UpdateLayout
          heading={"Quote details"}
          title={"Update Quote:"}
          width={"80%"}
          images={images}
          status={chipValue[quote.status]}
          requestId={quote?.request_id}
          quoteId={quote?.id}
        > 
          <form onSubmit={handleSubmit(updateData)}>
            <div className="block sm:grid sm:grid-cols-2 gap-2 my-5">
              <Select
                variant="bordered"
                labelPlacement={"outside"}
                label="&nbsp;"
                placeholder="Select Sales Rep"
                selectedKeys={[sales]}
                className="max-w-xs"
                {...register("sales_repId", {
                  required: false,
                  valueAsNumber: true,
                })}
              >
                <SelectItem key="" onClick={() => sumAssignee()}>
                  <div className="flex gap-2 items-center">
                    <IoAddSharp style={{ fontSize: "0.9rem" }} />
                    <p>Add Sales Reps</p>
                  </div>
                </SelectItem>
                {reps.map((rep) => (
                  <SelectItem
                    key={rep.id}
                    value={rep.SalesRepName}
                    onClick={() => setSales(String(rep.id))}
                  >
                    {rep.SalesRepName}
                  </SelectItem>
                ))}
              </Select>
              <div></div>
              <Input
                type="file"
                label="Upload images (Max 5 image)"
                classNames={{
                  input: inputStyle,
                  inputWrapper: inputWrapperStyle,
                }}
                className="mobile-only:mb-5"
                multiple
                accept="image/*"
                labelPlacement={"outside"}
                placeholder="Choose images"
                description={""}
                {...register("images", { required: false })}
                onChange={(e) => uploadImage(e)}
              />
              <Input
                type="date"
                label="Shipping Date"
                variant="bordered"
                labelPlacement={"outside"}
                placeholder="Enter date"
                description={""}
                className="w-full text-black mobile-only:mb-5"
                {...register("shipping_date", { required: false })}
              /> 

              <Select
                labelPlacement={"outside"}
                variant="bordered"
                className="w-full mobile-only:mb-5"
                label="Warranty Duration"
                selectedKeys={[String(warrantys)]}
                // id="duration"
                placeholder="warranty duration"
                {...register("warranty", { required: false })}
              >
                {warranty?.map((item) => {
                  const Value = `${item.duration} ${item.unit}`;
                  return (
                    <SelectItem
                      key={`${item.id}`}
                      value={item.id}
                      onClick={() => setWarrantyId(item.id)}
                    >
                      {Value}
                    </SelectItem>
                  );
                })}
              </Select>
              <Input
                type="number"
                label="Price AU$"
                placeholder="Price AU$"
                variant="bordered"
                labelPlacement={"outside"}
                className="mobile-only:mb-5"
                onWheel={(e) => e.target.blur()}
                description={""}
                {...register("part_price", {
                  required: false,
                  valueAsNumber: true,
                })}
              />
              <Input
                type="number"
                label="Freight Price"
                placeholder="Freight Price"
                variant="bordered"
                labelPlacement={"outside"}
                className="mobile-only:mb-5"
                description={""}
                onWheel={(e) => e.target.blur()}
                {...register("freight_price", {
                  required: false,
                  valueAsNumber: true,
                })}
              />
              <Input
                type="text"
                label="Warranty Option"
                placeholder="warranty option"
                variant="bordered"
                labelPlacement={"outside"}
                className="mobile-only:mb-5"
                description={""}
                {...register("warranty_option", { required: false })}
              />
              <Input
                type="number"
                label="Warranty Price $"
                placeholder="Warranty Price $"
                variant="bordered"
                className="mobile-only:mb-5"
                labelPlacement={"outside"}
                onWheel={(e) => e.target.blur()}
                description={""}
                {...register("warranty_price", {
                  required: false,
                  valueAsNumber: true,
                })}
              />
              <Input
                type="text"
                label="Interchange Number"
                placeholder="Interchange Number"
                variant="bordered"
                onWheel={(e) => e.target.blur()}
                className="mobile-only:mb-5"
                labelPlacement={"outside"}
                description={""}
                {...register("interchange_number", {
                  required: false,
                })}
              />
              {quote?.QuoteOptions?.length>0  &&  
              <div className="col-span-2">
                <h1 className="font-semibold mt-2">Offer Additional Options: </h1>
                {quote?.QuoteOptions?.map((option, index) =>
                  option?.Part ? (
                    <div className="block sm:grid sm:grid-cols-2 gap-3 mt-2">
                      <Select
                        labelPlacement={"outside"}
                        variant="bordered"
                        className="w-full mb-2 bg-white col-span-2 "
                        label="Part Type"
                        placeholder="Select part type"                          
                        selectedKeys={[option?.Part?.name]}
                      >
                        {part_type?.map(
                          (item) =>
                            item?.id !== 0 && (
                              <SelectItem key={item.name} value={item.name}>
                                {item.name}
                              </SelectItem>
                            )
                        )}
                      </Select>

                      <Input
                        type="number"
                        label="Quantity"
                        variant="bordered"
                        className="mb-2 "
                        labelPlacement={"outside"}
                        placeholder="0"
                        onWheel={(e) => e.target.blur()}
                        value={`${option?.quantity}`}
                        onKeyDown={(e) => {
                          if (
                            !/[0-9]/.test(e.key) &&
                            e.key !== "Backspace" &&
                            e.key !== "Delete"
                          ) {
                            e.preventDefault();
                          }
                        }}
                      />
                      <Input
                        type="number"
                        label="Price AU$"
                        variant="bordered"
                        className="mb-2 "
                        labelPlacement={"outside"}
                        placeholder="0"
                        onWheel={(e) => e.target.blur()}
                        value={`${option?.part_price}`}
                        onKeyDown={(e) => {
                          if (
                            !/[0-9]/.test(e.key) &&
                            e.key !== "Backspace" &&
                            e.key !== "Delete"
                          ) {
                            e.preventDefault();
                          }
                        }}
                      />

                      <Input
                        type="number"
                        label="Freight Price"
                        variant="bordered"
                        className="mb-2 "
                        labelPlacement={"outside"}
                        placeholder="0"
                        onWheel={(e) => e.target.blur()}
                        value={`${option?.freight_price}`}
                        onKeyDown={(e) => {
                          if (
                            !/[0-9]/.test(e.key) &&
                            e.key !== "Backspace" &&
                            e.key !== "Delete"
                          ) {
                            e.preventDefault();
                          }
                        }}
                      />
                      <Select
                        labelPlacement={"outside"}
                        variant="bordered"
                        className="w-full mobile-only:mb-5"
                        label="Warranty Duration"                  
                        selectedKeys={[String(option?.warrantyDuration?.duration)]}
                        placeholder="warranty duration"
                      >
                        {warranty?.map((item) => {
                          const Value = `${item.duration} ${item.unit}`;
                          return ( 
                            <SelectItem key={item.duration} value={item.duration}>
                              {Value}
                            </SelectItem>
                          );
                        })}
                      </Select>
                      {option?.warranty_option && (<Input
                        type="text"
                        label="Warranty Option"
                        variant="bordered"
                        className="mb-2 "
                        labelPlacement={"outside"}
                        placeholder="Warranty option"
                        value={`${option?.warranty_option}`}
                      />

                     )}
                     {option?.warranty_price &&  <Input
                        type="number"
                        label="Warranty Price"
                        variant="bordered"
                        className="mb-2 "
                        labelPlacement={"outside"}
                        placeholder="0"
                        onWheel={(e) => e.target.blur()}
                        value={`${option?.warranty_price}`}
                        onKeyDown={(e) => {
                          if (
                            !/[0-9]/.test(e.key) &&
                            e.key !== "Backspace" &&
                            e.key !== "Delete"
                          ) {
                            e.preventDefault();
                          }
                        }}
                      />

                     }
                    </div>
                  ) : (
                    <div> 
                      <Input
                        type="text"
                        label="Custom Part Type"
                        variant="bordered"
                        className="mb-2 col-span-2"
                        labelPlacement={"outside"}
                        placeholder="Custom Part Type"
                        value={`${option?.custom_part_type}`}
                      />
                      <Input
                        type="number"
                        label="Price AU$"
                        variant="bordered"
                        className="mb-2 col-span-2"
                        labelPlacement={"outside"}
                        placeholder="0"
                        onWheel={(e) => e.target.blur()}
                        value={`${option?.part_price}`}
                        onKeyDown={(e) => {
                          if (
                            !/[0-9]/.test(e.key) &&
                            e.key !== "Backspace" &&
                            e.key !== "Delete"
                          ) {
                            e.preventDefault();
                          }
                        }}
                      />
                      <Input
                        type="number"
                        label="Quantity"
                        variant="bordered"
                        className="mb-2 col-span-2"
                        labelPlacement={"outside"}
                        placeholder="0"
                        onWheel={(e) => e.target.blur()}
                        value={`${option?.quantity}`}
                        onKeyDown={(e) => {
                          if (
                            !/[0-9]/.test(e.key) &&
                            e.key !== "Backspace" &&
                            e.key !== "Delete"
                          ) {
                            e.preventDefault();
                          }
                        }}
                      />
                    </div>
                  )
                )}
              </div>}

              <Textarea
                key={"bordered"}
                variant={"bordered"}
                label="Notes"
                labelPlacement="outside"
                placeholder="Any message"
                className="col-span-2 "
                {...register("notes", { required: false })}
              />
              <div className="col-span-2">
                <div>
                  <p className="text-base mt-4 font-semibold">Transaction:</p>
                </div>
                <div>
                  <div className="mx-2 sm:mx-6 border-b-1 border-gray-300 pb-1 grid grid-cols-2">
                    <div className="text-left leading-7 font-semibold text-sm">
                      <p>Subtotal x {request?.quantity}</p>

                      <p>
                        Shipping Cost <span className="text-xs">(Inc GST)</span>
                      </p>
                      <p>Warranty</p>
                      <p>Tax (Inc GST)</p>
                    </div>
                    <div className="text-right leading-7 text-sm">
                      <p>
                        {watch("part_price")
                          ? "$" +
                            (watch("part_price") * request?.quantity).toFixed(1)
                          : "$00"}
                      </p>

                      <p>
                        {watch("freight_price")
                          ? "$" +
                            (watch("freight_price") +
                              Number(
                                (watch("freight_price") * (10 / 100)).toFixed(1)
                              ))
                          : "$00"}
                      </p>
                      <p>
                        {watch("warranty_price")
                          ? "$" + watch("warranty_price")
                          : "$00"}
                      </p>
                      <p>
                        $
                        {totalGST(request?.quantity)
                          ? totalGST(request?.quantity)
                          : "00"}
                      </p>
                    </div>
                  </div>
                  <div className="flex justify-between mx-2 sm:mx-6 py-2 text-base font-semibold">
                    <div>
                      <p>Grand Total</p>
                    </div>
                    <div>
                      <p>
                        {totalGST(request?.quantity)
                          ? "$" + totalBill(request?.quantity)
                          : "$00"}
                      </p>
                    </div>
                  </div>
                </div>
              </div>
              {quote.status === 1 || quote.status === 2 ? (
                <div className="flex mobile-only:flex-col gap-5">
                  <Button
                    type="submit"
                    className=" bg-lightBlue text-white py-1 px-5 mt-6"
                  >
                    Update
                  </Button>
                  <Button
                    className=" bg-purpleBtn text-white py-1 px-5 mt-6"
                    onClick={() => onOpen()}
                  >
                    Cancel
                  </Button>
                </div>
              ) : (
                " "
              )}
            </div>
          </form>
        </UpdateLayout>
      ) : quote?.status === 3 || quote?.status === 8 ? (
        <AcceptOrder id={id} />
      ) : (
        ""
      )}

      {/* ChatBox */}
      <ChatBox quoteId={id} />

      {/* modal for cancel request */}
      <Modal size={"xs"} isOpen={isOpen} onClose={onClose} placement="center">
        <ModalContent>
          {(onClose) => (
            <>
              <ModalHeader className="flex flex-col gap-1 justify-center">
                Are you sure?
              </ModalHeader>
              <ModalBody>
                <div className="grid grid-cols-2 gap-2 pb-3">
                  <Button
                    className="border-2 border-orange-500 text-orange-500 bg-transparent hover:bg-orange-500 hover:text-white hover:border-none"
                    onPress={onClose}
                  >
                    No
                  </Button>
                  <Button
                    className="border-2 border-green-500 text-green-500 bg-transparent hover:bg-green-500 hover:text-white hover:border-none"
                    onClick={cancelQuote}
                  >
                    Yes
                  </Button>
                </div>
              </ModalBody>
            </>
          )}
        </ModalContent>
      </Modal>

      {/* modal for add assignee */}
      <AddAssignee addAssignee={addAssignee} closeAssignee={closeAssignee} />
    </div>
  );
};

export default UpdateQuote;
