import React, { useState, useEffect } from "react";
import {
  Dropdown,
  DropdownTrigger,
  DropdownMenu,
  DropdownItem,
  User,
} from "@nextui-org/react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { FaUser } from "react-icons/fa6";
import { removeAuth } from "../store/slices/authSlice";
import axios from "axios";

const UserMenu = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  let role = localStorage.getItem("role");
  let user_role = JSON.parse(localStorage.getItem('user_details'))?.role;
  const accessToken = localStorage.getItem("accessToken");
  const { user } = useSelector((state) => state.auth);
  const [img, setImg] = useState("");
  
  useEffect(() => {
    const uploadImage = () => {
      setImg(user?.profile_id);
    };

    uploadImage();
  }, [img, user, user?.profile_id]);

  const handleLogout = () => {
    dispatch(removeAuth());
  };

  const userProfile = () => {
    navigate("/user-profile");
  };

  const helpFeedback = () => {
    navigate("/help&feedback");
  };

  const showDisputes = () => {
    navigate("/support")
  }

  const switchAccount = async () => {
    let data = {};
    if(role==="SUPPLIER"){
      data = {
        role:"CUSTOMER"
      }
    }else{
      data = {
        role:"SUPPLIER"
      }
    }
    try {
      const res = await axios.put(
        `${process.env.REACT_APP_SERVER_URL}/users/togglemode`,
        data,
        {
          headers: {
            authorization: `Bearer ${accessToken}`,
          },
        }
      );

      localStorage.setItem("role", res.data?.role);
      localStorage.setItem("accessToken", res.data.accessToken)
      window.location.reload();
    } catch (error) {
      console.log(error);
    }
  }

  return (
    <Dropdown placement="bottom-start">
      <DropdownTrigger className="sm:[&>*:last-child]:inline-flex [&>*:last-child]:hidden">
        <User
          as="button"
          avatarProps={{
            isBordered: false,
            src: img,
            showFallback: true,
            fallback: <FaUser fontSize={18} color="gray" />,
          }}
          className="transition-transform font-semibold"
          description={role}
          name={
            user?.first_name || user?.last_name
              ? user?.first_name + " " + user?.last_name
              : ""
          }
        />
      </DropdownTrigger>
      <DropdownMenu aria-label="User Actions" variant="flat">
        <DropdownItem key="profile" className="pointer-events-none">
          <p className="text-xs">Signed in as</p>
          <p className="font-bold">{user?.role}</p>
        </DropdownItem>

       {
        user?.role==="SUPPLIER" ?  <DropdownItem onClick={()=>switchAccount()}>
        Switch as {role==="SUPPLIER" ? "Customer" : "Supplier"}
      </DropdownItem>  : null
       }
        <DropdownItem key="settings" onClick={userProfile}>
          My Settings
        </DropdownItem>
        <DropdownItem key="help_and_feedback" onClick={helpFeedback}>
          Help & Feedback
        </DropdownItem>
       {
        user_role !== "ADMIN" &&  <DropdownItem key="help_and_feedback" onClick={showDisputes}>
        Support
      </DropdownItem>
       }
        <DropdownItem key="logout" color="danger" onClick={handleLogout}>
          Log Out
        </DropdownItem>
      </DropdownMenu>
    </Dropdown>
  );
};

export default UserMenu;
