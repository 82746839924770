import React, { useEffect } from 'react';
import { getQuoteById } from '../../store/slices/customerSlice';
import AcceptQuoteLayout from '../../layouts/AcceptQuoteLayout';
import { useDispatch, useSelector } from 'react-redux';
import OrderGenerate from "../Customer/OrderGenerate";
import { useParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import axios from 'axios';

const AcceptOrder = () => {
  const { id } = useParams();
  const dispatch = useDispatch();
  const { orderDetails } = useSelector((state) => state.customer);
  const { orderData } = useSelector((state) => state.supplier);
  const token = localStorage.getItem("accessToken");

  const handleOrder = async () =>{
    try {
      const header = {
        headers:{
          "Authorization": `Bearer ${token}`
        }
      } 
      let res = await axios.post(`${process.env.REACT_APP_SERVER_URL}/orders/generate/${id}`, "", header);
      toast.success(`${res.data?.message} ⭐`);
      dispatch(getQuoteById(id));
    } catch (error) {
      let err = error.response?.data?.message;
      toast.error(err);
    }
  }
       
  useEffect(()=>{
    dispatch(getQuoteById(id));
  },[])


  useEffect(()=>{},[orderDetails, orderData])
    return (
    <div>
      <AcceptQuoteLayout id={id} handleOrder={handleOrder} />
      {orderData && orderDetails ? <OrderGenerate orderData={orderData} showButton={true} />:""}
    </div>
    )
}

export default AcceptOrder;