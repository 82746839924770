export const inputStyle = [
    "file:py-[9px]",
    "file:pl-4",
    "file:pr-6",
    "p-0",
    "file:border-none",
    "file:bg-regalBlue",
    "file:rounded-l-lg",
    "file:text-white",
  ]

  export const inputWrapperStyle = [
    "text-gray-900",
    "border border-gray-300",
    "rounded-lg",
    "cursor-pointer",
    "bg-gray-50",
    "dark:text-gray-400",
    "focus:outline-none",
    "dark:bg-gray-700",
    "dark:border-gray-600",
    "dark:placeholder-gray-400",
    "p-0",
  ]