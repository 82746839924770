import { createSlice } from "@reduxjs/toolkit";
import { STATUS } from "../constants";
import { toast } from "react-toastify";
import axios from "axios";

export const order_status = [
  {
    id: 1,
    status: "Initiated",
    completed: false,
  },
  {
    id: 2,
    status: "Processing",
    completed: false,
  },
  {
    id: 3,
    status: "Shipped",
    completed: false,
  },
  {
    id: 4,
    status: "Transit",
    completed: false,
  },
  {
    id: 5,
    status: "Completed",
    completed: false,
  },
  {
    id: 6,
    status: "Received",
    completed: false,
  },
];

const initialState = {
  request: [],
  quotes: [],
  disputes: [],
  orders: [],
  images: [],
  status: STATUS.LOADING,
  orderData: {},
  orderStatus: [],
  error: "",
};

export const supplierSlice = createSlice({
  name: "supplier",
  initialState: initialState,
  reducers: {
    createQuote: (state, action) => {
      const { payload } = action;
      return {
        ...state,
      };
    },
    orderDataById: (state, action) => {
      let value = action.payload;
      return {
        ...state,
        orderData: value,
        orderStatus: order_status,
      };
    },
    getAllQuotes: (state, action) => {
      const { payload } = action;
      return {
        ...state,
        quotes: payload,
      };
    },
    getAllDisputes: (state, action) => {
      const { payload } = action;
      return {
        ...state,
        disputes: payload,
      };
    },
    getAllOrders: (state, action) => {
      const { payload } = action;
      return {
        ...state,
        orders: payload,
      };
    },
    changeStatus: (state, action) => {
      return {
        ...state,
        status: action.payload,
      };
    },
    isError: (state, action) => {
      return {
        ...state,
        status: STATUS.ERROR,
        error: action.payload,
      };
    },
  },
});

export const {
  createQuote,
  orderDataById,
  getAllQuotes,
  getAllDisputes,
  getAllOrders,
  changeStatus,
  isError,
} = supplierSlice.actions;
export default supplierSlice.reducer;

export const createSupQuote = (id, data) => {
  return async (dispatch) => {
    try {
      let token = localStorage.getItem("accessToken");
      let header = {
        headers: {
          "Content-Type": "multipart/form-data",
          authorization: `Bearer ${token}`,
        },
      };
      let res = await axios.post(
        `${process.env.REACT_APP_SERVER_URL}/quotes/create/${id}`,
        data,
        header
      );
      dispatch(changeStatus(STATUS.IDLE));
    } catch (error) {
      let err = error.response.data.message;
      dispatch(changeStatus(STATUS.ERROR));
      dispatch(isError(err));
    }
  };
};

export const getOrderDataById = (id) => {
  return async (dispatch) => {
    try {
      let token = localStorage.getItem("accessToken");
      const header = {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      };
      let res = await axios.get(
        `${process.env.REACT_APP_SERVER_URL}/orders/${id}`,
        header
      );
      let data = res.data.details;
      const foundObject = order_status.find(
        (obj) => `${obj.status}` === data?.OrderStatus?.status
      );
      if (foundObject) {
        const index = order_status.indexOf(foundObject);
        for (let i = 0; i <= index; i++) {
          order_status[i].completed = true;
        }
      }
      dispatch(orderDataById(data?.order));
    } catch (error) {
      let err = error.response?.data?.message;
      toast.error(err);
    }
  };
};
