import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  totalData: {},
};

export const formSlice = createSlice({
  name: 'form',
  initialState,
  reducers: {
    setFormData: (state, action) => {
    //   formData = action.payload;
    return{
        ...state,
        totalData: action.payload
    }
    }
   
  },
});

export const {
    setFormData
}=formSlice.actions;

export default formSlice.reducer;

export const getFormData=(data)=>{
    return async(dispatch)=>{
        dispatch(setFormData(data))
    }
}

