import AcceptQuoteLayout from '../../layouts/AcceptQuoteLayout';
import { getQuoteById } from '../../store/slices/customerSlice';
import { useDispatch, useSelector } from 'react-redux';
import OrderGenerate from "../Customer/OrderGenerate";
import { useParams } from 'react-router-dom';
import React, { useEffect } from 'react';
 
const AcceptQuote = () => {
  const { id } = useParams();
  const dispatch = useDispatch();
  const { orderDetails } = useSelector((state) => state.customer);
  const { orderData } = useSelector((state) => state.supplier);
   
  const handleOrder = () => {
    console.log("check order status");
  }
 
  useEffect(()=>{
    dispatch(getQuoteById(id));
  },[dispatch, id]);

  useEffect(()=>{},[orderDetails, orderData])
    
  return (
    <div>
      <AcceptQuoteLayout id={id} handleOrder={handleOrder} />
      {orderDetails && orderData ? <OrderGenerate orderData={orderData} />:""}
    </div>
  )
} 

export default AcceptQuote;
