import React, { useState, useEffect, useCallback, useMemo } from "react";
import { User, Chip } from "@nextui-org/react";
import { IoMdAdd } from "react-icons/io";
import { GrClose } from "react-icons/gr";
import { FiSearch } from "react-icons/fi";
import { STATUS } from "../store/constants";
import { changeStatus } from "../store/slices/supplierSlice";
import Addfields from "../pages/Admin/Addfields";
import { useForm } from "react-hook-form";
import { useNavigate } from "react-router-dom";
import { FaUser } from "react-icons/fa6";
import {statusOptions,users} from "../data/quotesData"
import {
  Dropdown,
  DropdownTrigger,
  DropdownMenu,
  DropdownItem,
} from "@nextui-org/react";

import {
  Input,
  Select,
  SelectItem,
  Modal,
  ModalContent,
  ModalHeader,
  ModalBody,
  useDisclosure,
  Spinner,
  Pagination,
  Divider,
} from "@nextui-org/react";
import axios from "axios";
import { useSelector, useDispatch } from "react-redux";
import {
  Table,
  TableHeader,
  TableColumn,
  TableBody,
  TableRow,
  TableCell,
  Button,
  Tabs,
  Tab,
} from "@nextui-org/react";
import { displayDate, formatTime } from "../utils/index";

const Users = () =>{

  const [filterValue, setFilterValue] = useState("");
  const [img, setImg] = useState("");
  const [statusFilter, setStatusFilter] = useState("CUSTOMER");
  const { status } = useSelector((state) => state.supplier);
  const navigate = useNavigate();
  let token = localStorage.getItem("accessToken");
  const [data, setData] = useState([]);
  const [property, setProperty] = useState("");
  const [sortDescriptor,setSortDescriptor]=useState({  
    column: "first_name",
    direction: "ascending",})
  const [fieldID, setFieldID] = useState();
  const dispatch = useDispatch();
  const {
    control,
    formState: { errors },
  } = useForm();
  const [rowsPerPage, setRowsPerPage] = useState(6);
  const [page, setPage] = useState(1);
  const [totalPages, setTotalPages] = useState();
  const [totalRecords, setTotalRecords] = useState();
  const [firstName,setFirstName]=useState("")
  const [fieldName,setFieldName]=useState([])

  const [selectedKeys, setSelectedKeys] = React.useState(new Set(["Select"]));

  const selectedValue = React.useMemo(
    () => Array.from(selectedKeys).join(", ").replaceAll("_", " "),
    [selectedKeys]
  );

  const userDetails = (id) => {
    if (statusFilter !== "disputes") {
      navigate(`/updateprofile/${id}`);
    } else {
      navigate(`/disputes/${id}`);
    }
  };

  const getDetails = async (page, pageSize) => {
    let header = {
      headers: {
        authorization: `Bearer ${token}`,
      },
    };

    try {
      dispatch(changeStatus(STATUS.LOADING));
      setData([]);
      let res = await axios.get(
        `${process.env.REACT_APP_SERVER_URL}/admin/${
          statusFilter !== "disputes"
            ? `getAlluser?role=${statusFilter}`
            : "disputelist"
        }${statusFilter !== "disputes" ? "&" : "?"}page=${page}&${
          statusFilter !== "disputes" ? "limit" : "pageSize"
        }=${pageSize}`,
        header
      );
   
      if (statusFilter !== "disputes") {
        setData(res.data.details?.users);
      } else {
        setData(res.data.details?.disputes);
      }

      let index = res.data?.details?.index;
      data.sort((a, b) => {
        let date1 = new Date(a?.createdAt);
        let date2 = new Date(b?.createdAt);
        return date2 - date1;
      });
      
      setTotalPages(index?.totalPages);
      setTotalRecords(index?.totalRecords);
      
      dispatch(changeStatus(STATUS.IDLE));
    } catch (error) {
      dispatch(changeStatus(STATUS.ERROR));
      console.log(error);
    }
  };

  useEffect(() => {
    getDetails(page, rowsPerPage);
  }, [dispatch, token, statusFilter, page, totalPages, rowsPerPage]);

  useEffect(() => {}, [data, totalPages, status]);

  const hasSearchFilter = Boolean(filterValue) || Boolean(firstName);

  const pages = totalPages;



  const headerusers = useMemo(() => {
    if (statusFilter === "CUSTOMER") return users;

    return users.filter((column) =>
      Array.from(statusFilter).includes(users.uid)
    );
  }, [statusFilter]);

  const filteredItems = useMemo(() => {
    let filteredData = [...data];

    // search-filter
    if (hasSearchFilter) {
        filteredData = filteredData.filter(
          (data) =>
            data.first_name
              .toLowerCase()
              .includes(filterValue.toLowerCase()) ||
             data.last_name
              .toLowerCase()
              .includes(filterValue.toLowerCase()) ||
            data.email
              .toLowerCase()
              .startsWith(filterValue.toLowerCase()) ||
            filterValue.split(',').map(Number).includes(data.first_name) ||
            fieldName.includes(data.first_name) && filterValue.toLowerCase().split(",").includes(data.last_name.toLowerCase()) 
        );
    } 
    return filteredData;
  }, [data, hasSearchFilter, statusFilter, filterValue]);


  

  const onRowsPerPageChange = useCallback((e) => {
    setRowsPerPage(Number(e.target.value));
    setPage(1);
    getDetails(1, e.target.value);
  }, []);

  const items = useMemo(() => {
    return filteredItems;
  }, [page, data, filteredItems, rowsPerPage]);


  const sortedItems = useMemo(() => {
    return [...items].sort((a, b) => {
      const first = a[sortDescriptor.column];
      const second = b[sortDescriptor.column];
      const cmp = first < second ? -1 : first > second ? 1 : 0;
      return sortDescriptor.direction === "descending" ? -cmp : cmp;
    });
    
  }, [sortDescriptor, items,data]);

  const onSearchChange = useCallback((value, type) => {
    if (value) {
      setFilterValue(value);
    } else {
      setFilterValue("");
    }
  }, []);

  const onClear = useCallback(() => {
    setFilterValue("");
  }, []);
 
  const topContent = useMemo(() => {
    return (
      <div className="flex flex-col gap-6">
         <div className="flex justify-between items-center gap-3">
          <Input
            isClearable
            className="w-full sm:max-w-[44%]"
            placeholder="Search users..."
            startContent={<FiSearch />}
            value={filterValue}
            onClear={() => onClear()}
            onValueChange={onSearchChange}
          />
        </div>
        <div className="flex flex-col-reverse sm:flex-row flex-wrap items-center justify-between gap-y-4">
          <div>
            <Tabs
              aria-label="Options"
              variant="underlined"
              selectedKey={statusFilter}
              onSelectionChange={setStatusFilter}
            >
              <Tab
                key="CUSTOMER"
                title="All Customer"
             
              ></Tab>
              <Tab
                key="SUPPLIER"
                title="All Supplier"
                
              ></Tab>
              <Tab
                key="disputes"
                title="All Disputes"
              ></Tab>
            </Tabs>
          </div>

          <div className="flex items-center gap-5 text-xs sm:text-sm text-default-400">
            <span>
              Total: {totalRecords}{" "}
              {statusFilter !== "disputes" ? "users" : "disputes"}
            </span>
            <Divider orientation="vertical" className="h-6" />
            <label className="flex items-center">
              Rows per page:
              <select
                className="bg-transparent outline-none"
                onChange={onRowsPerPageChange}
              >
                <option value="6">6</option>
                <option value="10">10</option>
                <option value="15">15</option>
              </select>
            </label>
          </div>
        </div>
      </div>
    );
  }, [statusFilter, data.length, onRowsPerPageChange,onClear,filterValue,
    onSearchChange]);


  const bottomContent = useMemo(() => {
    return (
      <div className="py-2 px-2 flex justify-center items-center">
        <Pagination
          isCompact
          showControls
          showShadow
          color="primary"
          initialPage={page}
          total={pages === 0 ? pages + 1 : pages}
          onChange={setPage}
        />
      </div>
    );
  }, [statusFilter, page, pages]);

  return (
    <div>
       <Table
        aria-label="User Table"
        isHeaderSticky
        isStriped
        topContent={topContent}
        topContentPlacement="outside"
        bottomContent={bottomContent}
        bottomContentPlacement="outside"
        sortDescriptor={sortDescriptor}
        onSortChange={setSortDescriptor}
        classNames={{
          td: "whitespace-nowrap",
        }}
      >
        <TableHeader users={headerusers}  
        >
          <TableColumn key={users.uid}
            align={users.uid === "actions" ? "center" : "start"} 
            allowsSorting={users.sortable}>
            {statusFilter !== "disputes" ? "Profile" : "Role"}
          </TableColumn>
          
          <TableColumn 
          key={users.uid}
          align={users.uid === "actions" ? "center" : "start"}
          allowsSorting={users.sortable}>
            {statusFilter !== "disputes" ? "Name" : "Car"}
          </TableColumn>
          <TableColumn 
           key={users.uid}
           align={users.uid === "actions" ? "center" : "start"}
          allowsSorting={users.sortable}>
            {statusFilter !== "disputes" ? "Email" : "Part"}
          </TableColumn>
          <TableColumn >
            {statusFilter !== "disputes" ? "Phone no" : "Created"}
          </TableColumn>
        </TableHeader>
        <TableBody
          emptyContent={
            statusFilter !== "disputes" ? "No User found" : "No Dispute found"
          }
          isLoading={status === STATUS.LOADING && !data.length}
          items={sortedItems}
          loadingContent={
            <Spinner label="Loading..." className="w-full bg-white" />
          }
        >
          {(item) => (
            <TableRow
              className=" table_cell font-600"
              key={item.id}
              onClick={() => userDetails(item.id)}
            >
              <TableCell className="table_cell text-[15px] font-semibold" >
                {statusFilter !== "disputes" ? (
                  item?.profile_id ? (
                    <img className="w-10" src={`${item?.profile_id}`} />
                  ) : (
                    <User
                      as="button"
                      avatarProps={{
                        isBordered: false,
                        src: img,
                        showFallback: true,
                        fallback: <FaUser fontSize={18} color="gray" />,
                      }}
                    />
                  )
                ) : (
                  <Chip variant="flat" className="capitalize bg-[#f3f3f3]">
                    {item?.User?.role}
                  </Chip>
                )}
              </TableCell>
              <TableCell className="text-md font-semibold">

                {statusFilter !== "disputes" ? (
                  `${item.first_name} ${item.last_name}`
                 
                ) : (
                  <p>
                    {item?.Order?.Request?.VehicleMake?.name
                      ? item?.Order?.Request?.VehicleMake?.name
                      : item?.Order?.Request?.custom_make}{" "}
                    {item?.Order?.Request?.VehicleModel?.name
                      ? item?.Order?.Request?.VehicleModel?.name
                      : item?.Order?.Request?.custom_model}
                  </p>
                )}
             
              </TableCell>
              {statusFilter !== "disputes" ? (
                <TableCell className="text-sm">{item.email}</TableCell>
              ) : (
                <TableCell className="text-md font-semibold">
                 
                  {item?.Order?.Request?.Part?.name
                    ? item?.Order?.Request?.Part?.name
                    : item?.Order?.Request?.custom_part_type}
                </TableCell>
              )}
 

              <TableCell className="text-md font-semibold">
                {statusFilter !== "disputes" ? (
                  item.phone
                ) : (
                  <div className="flex flex-col gap-1">
                    <p className="table_cell normal-case">
                      {formatTime(item?.createdAt)}
                    </p>
                    <p className="table_cell_sm">
                      {displayDate(item?.createdAt)}
                    </p>
                  </div>
                )}
              </TableCell>
            </TableRow>
          )}
        </TableBody>
      </Table>
    </div>
  )
  }


  export default Users;