import React, { useEffect, useState } from "react";
import { Button, User } from "@nextui-org/react";
import { useParams } from "react-router-dom";
import axios from "axios";
import { FaUser } from "react-icons/fa6";
import { useNavigate } from "react-router-dom";

export const DisputeUpdate = () => {
  let { id } = useParams();
  const navigate = useNavigate();
  const [img, setImg] = useState(" ");
  let token = localStorage.getItem("accessToken");
  const [data, setData] = useState([]);
  const [info, setInfo] = useState([]);
  const [customer, setCustomer] = useState([]);
  const [supplier, setSupplier] = useState([]);

  const fetchDisputes = async () => {
    try {
      const header = {
        headers: {
          "Content-Type": "multipart/form-data",
          Authorization: `Bearer ${token}`,
        },
      };
      let res = await axios.get(
        `${process.env.REACT_APP_SERVER_URL}/admin/getdispute?disputeId=${id}`,
        header
      );
      console.log(res.data);
      setData(res?.data?.details?.Order);
      setInfo(res?.data?.details);
      fetchUser(res?.data?.details?.Order.customer_id, "CUSTOMER");
      fetchUser(res?.data?.details?.Order.supplier_id, "SUPPLIER");
    } catch (error) {
      console.log(error);
    }
  };

  const fetchUser = async (id, role) => {
    if (role === "CUSTOMER") {
      try {
        const header = {
          headers: {
            "Content-Type": "multipart/form-data",
            Authorization: `Bearer ${token}`,
          },
        };
        let res = await axios.get(
          `${process.env.REACT_APP_SERVER_URL}/admin/getuser?userId=${id}`,
          header
        );
        console.log(res.data);
        setCustomer(res.data);
      } catch (error) {
        console.log(error);
      }
    } else {
      try {
        const header = {
          headers: {
            "Content-Type": "multipart/form-data",
            Authorization: `Bearer ${token}`,
          },
        };
        let res = await axios.get(
          `${process.env.REACT_APP_SERVER_URL}/admin/getuser?userId=${id}`,
          header
        );
        console.log(res.data);
        setSupplier(res.data);
      } catch (error) {
        console.log(error);
      }
    }
  };

  useEffect(() => {
    fetchDisputes();
  }, []);

  return (
    <div className="grid md:grid-cols-2 gap-3 box-border w-full">
      <div className="flex justify-center pt-9 font-semibold">
        No image found
      </div>

      <div className="pt-2">
        <div>
          <div className="mb-4">
            <h2 className="font-semibold border-b-1 border-gray-300 pb-2 mb-5">
              Customer Details
            </h2>
            <div className="flex items-center">
              {customer?.details?.profile_id ? (
                <img
                  src={customer?.details?.profile_id}
                  className="w-[80px] rounded-full"
                  alt=""
                />
              ) : (
                <User
                  as="button"
                  className="w-[80px]"
                  avatarProps={{
                    isBordered: false,
                    src: img,
                    showFallback: true,
                    fallback: <FaUser fontSize={18} color="gray" />,
                  }}
                />
              )}
              <div className="[&>div>p]:font-semibold [&>div>p]:text-[0.9rem] ml-3">
                <p className="font-semibold text-[1rem]">
                  {customer?.details?.first_name} {customer?.details?.last_name}{" "}
                </p>
                <p className="font-semibold text-[0.8rem] text-gray-500">
                  {customer?.details?.phone}
                </p>
                <p className="font-semibold text-[0.8rem]  text-gray-500">
                  {customer?.details?.email}
                </p>
              </div>
            </div>
            <div className="grid grid-cols-3 ml-2  [&>div>p:first-child]:font-semibold [&>div>p]:text-[0.9rem] mt-2">
              <div>
                <p>City</p>
                <p>{customer?.details?.suburb}</p>
              </div>
              <div>
                <p>State</p>
                <p>{customer?.details?.state}</p>
              </div>
              <div>
                <p>Postcode</p>
                <p>{customer?.details?.postcode}</p>
              </div>
            </div>
          </div>
          <div className="mt-4 mb-2">
            <h2 className="font-semibold border-b-1 border-gray-300 pb-2 mb-5">
              Supplier Details
            </h2>

            <div className="flex items-center justify-between">
              <div className="flex items-center">
                {supplier?.details?.profile_id ? (
                  <img
                    src={supplier?.details?.profile_id}
                    className="w-[80px] rounded-full"
                    alt=""
                  />
                ) : (
                  <User
                    as="button"
                    className="w-[80px] "
                    avatarProps={{
                      isBordered: false,
                      src: img,
                      showFallback: true,
                      fallback: <FaUser fontSize={18} color="gray" />,
                    }}
                  />
                )}
                <div className="[&>div>p]:font-semibold [&>div>p]:text-[0.9rem] ml-3">
                  <p className="font-semibold text-[1rem]">
                    {supplier?.details?.first_name}{" "}
                    {supplier?.details?.last_name}
                  </p>
                  <p className="font-semibold text-[0.8rem]  text-gray-500">
                    {supplier?.details?.phone}
                  </p>
                  <p className="font-semibold text-[0.8rem]   text-gray-500">
                    {supplier?.details?.email}
                  </p>
                </div>
              </div>

              {supplier?.details?.blocked === true && (
                <Button className="bg-red-400 text-white font-semibold px-5 mt-3 mobile-only:w-full">
                  Blocked
                </Button>
              )}
            </div>
            <div className="grid grid-cols-3 ml-2  [&>div>p:first-child]:font-semibold [&>div>p]:text-[0.9rem] mt-2">
              <div>
                <p>City</p>
                <p>{supplier?.details?.suburb}</p>
              </div>
              <div>
                <p>State</p>
                <p>{supplier?.details?.state}</p>
              </div>
              <div>
                <p>Postcode</p>
                <p>{supplier?.details?.postcode}</p>
              </div>
            </div>
          </div>
        </div>
        <div>
          <Button
            className="bg-purpleBtn text-white py-2 px-5 font-bold text-sm sm:text-base mt-5"
            onClick={() => navigate("/users")}
          >
            Go Back
          </Button>
        </div>
      </div>

      <div className="my-7 border-1 border-gray-300 rounded-lg  shadow-inner col-span-4 border-b-1 sm:border-r-1 ">
        <div className="flex justify-between items-center py-2 md:py-3 px-3 md:px-5 border-b-1 border-gray-200">
          <p className="text-sm font-semibold">
            <span>Tracking Number : </span>
            <span className="text-red-500">{data?.tracking_number}</span>
          </p>
        </div>
        <div className="p-7 sm:p-3 md:p-5">
          <div className="grid sm:grid-cols-3 gap-1 ">
            <div className="!mt-0 order-generation-text">
              <p>
                <span>Make : </span>
                <span>
                  {data?.Request?.VehicleMake
                    ? data?.Request?.VehicleMake?.name
                    : data?.Request?.custom_make}
                </span>
              </p>
              <p>
                <span>Modal : </span>
                <span>
                  {data?.Request?.VehicleModel
                    ? data?.Request?.VehicleModel?.name
                    : data?.Request?.custom_model}
                </span>
              </p>
              <p>
                <span>Part : </span>
                <span>
                  {data?.Request?.Part
                    ? data?.Request?.Part.name
                    : data?.Request?.custom_part_type}
                </span>
              </p>
              <p>
                <span>Quantity : </span>
                <span>{data?.Request?.quantity} Qnty</span>
              </p>
              <p>
                <span>Rego : </span>
                <span>{data?.Request?.rego}</span>
              </p>
              <p>
                <span>Fuel Type : </span>
                <span>{data?.Request?.fuelType?.name}</span>
              </p>
              <p>
                <span>Sales Rep : </span>
                <span>{data?.Quote?.SupplierSalesRep?.SalesRepName}</span>
              </p>
            </div>
            <div className="order-generation-text">
              <p>
                <span>Part Price : </span>
                <span>${data?.part_price}</span>
              </p>
              <p>
                <span>Freight Price : </span>
                <span>${data?.freight_price}</span>
              </p>
              <p>
                <span>Total Price (Inc GST) : </span>
                <span>${data?.Quote?.total_price}</span>
              </p>
              <p>
                <span>Warranty : </span>
                <span>
                  {data?.Quote?.warrantyDuration?.duration}{" "}
                  {data?.Quote?.warrantyDuration?.unit}
                </span>
              </p>
              <p>
                <span>Warranty Price : </span>
                <span>
                  {data?.Quote?.warranty_price
                    ? data?.Quote?.warranty_price
                    : "Not provided"}{" "}
                </span>
              </p>
              <p>
                <span>Warranty Option : </span>
                <span>
                  {data?.Quote?.warranty_option
                    ? data?.Quote?.warranty_option
                    : "Not provided"}
                </span>
              </p>
            </div>
            <div className="order-generation-text">
              <p>
                <span>Transmission : </span>
                <span>{data?.Request?.transmissionModel?.name}</span>
              </p>
              <p>
                <span>Tracking Carrier : </span>
                <span>{data?.tracking_carrier}</span>
              </p>
              <p>
                <span>Shipping Date : </span>
                <span>{data?.date_ship?.split("T")[0]}</span>
              </p>
              <p>
                <span>Order Date : </span>
                <span>{data?.date_ordered?.split("T")[0]}</span>
              </p>
              <p>
                <span>Body Style : </span>
                <span>{data?.Request?.bodyStyle?.name}</span>
              </p>
            </div>
          </div>
          <div className="[&>p>span]:text-xs text-center sm:text-left md:[&>p>span]:text-sm [&>p>span:first-child]:font-semibold leading-8">
            <p>
              <span>Shipping Address : </span>
              <span>{data?.Quote?.shipping_address}</span>
            </p>

            <p>
              <span>Title : </span>
              <span>{info?.titlee?.name}</span>
            </p>
            <p>
              <span>Notes : </span>
              <span>{data?.notes}</span>
            </p>
            <p>
              <span>Description : </span>
              <span>
                {info?.description?.split(":")[1]} [{" "}
                {info?.description?.split(":")[0]} ]
              </span>
            </p>
          </div>
        </div>
      </div>

      <div className="my-7 border-1 border-gray-300 rounded-lg  shadow-inner col-span-4 border-b-1 sm:border-r-1 ">
        <div className="flex justify-between items-center py-2 md:py-3 px-3 md:px-5 border-b-1 border-gray-200">
          <p className="text-sm font-semibold">Additional Offers:</p>
        </div>
        <div className="p-7 sm:p-3 md:p-5">
          {data?.Quote?.QuoteOptions.map(
            (e) =>
              e?.approval === true && (
                <div className="grid sm:grid-cols-3 gap-1 mt-2">
                  <div className="!mt-0 order-generation-text">
                    <p>
                      <span>
                        {e?.Part?.name ? "Part : " : "Custom Part : "}{" "}
                      </span>
                      <span>
                        {e?.Part?.name ? e?.Part?.name : e?.custom_part_type}
                      </span>
                    </p>

                    <p>
                      <span>Quantity : </span>
                      <span>{e?.quantity}</span>
                    </p>

                    <p>
                      <span>Part Price : </span>
                      <span>${e?.part_price}</span>
                    </p>
                  </div>
                  <div className=" order-generation-text border-b-2 border-dashed pb-2">
                    <p>
                      <span>Warranty : </span>
                      <span>
                        {e?.warrantyDuration?.duration}{" "}
                        {e?.warrantyDuration?.unit}
                      </span>
                    </p>
                    <p>
                      <span>Warranty Option: </span>
                      <span>
                        {e?.warranty_option
                          ? e?.warranty_option
                          : "Not Provided"}
                      </span>
                    </p>
                    <p>
                      <span>Warranty Price: </span>
                      <span>
                        ${e?.warranty_price ? e?.warranty_price : "0"}
                      </span>
                    </p>
                  </div>
                  <div className=" order-generation-text">
                    <p>
                      <span>Freight Price : </span>
                      <span>${e?.freight_price}</span>
                    </p>

                    <p>
                      <span>Total Price: </span>
                      <span>${e?.total}</span>
                    </p>
                  </div>
                </div>
              )
          )}
        </div>
      </div>
    </div>
  );
};
